import PropTypes from 'prop-types';
import React from 'react';

const currencyFormatted = (amount) => {
  if (typeof amount !== 'number') {
    return '$0.00';
  }
  return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

const TotalAmount = ({ amount }) => {
  return (
    <div
      style={{
        display: 'block',
        backgroundColor: '#F3F3F3',
        color: '#B9B9B9',
        borderRadius: '5px',
        border: 'solid 1px #d4d4d4',
        padding: '5px 5px 5px 10px',
      }}
    >
      {currencyFormatted(amount)}
    </div>
  );
};

TotalAmount.propTypes = {
  amount: PropTypes.number,
};

export default TotalAmount;
