const getDeviceType = () => {
  const width = window.innerWidth;

  if (width >= 992) {
    return { device: 'desktop', orientation: null };
  } else if (width >= 768) {
    return { device: 'tablet', orientation: getOrientation() };
  } else {
    return { device: 'mobile', orientation: getOrientation() };
  }
};

const getOrientation = () => {
  if (window.matchMedia('(orientation: portrait)').matches) {
    return 'portrait';
  } else if (window.matchMedia('(orientation: landscape)').matches) {
    return 'landscape';
  }
};

export default getDeviceType;
