import React from 'react';
import styled from 'styled-components';
import { Row, Col, Badge, Tooltip, Typography } from 'antd';
import { MinusCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import { PropTypes } from 'mobx-react';
import _ from 'lodash';

import { getPriorityTagColor } from 'services/Issue';
import { prescriptiveMaintenanceMessages, customFleets } from 'shared';
import { CurrentUserStore, ShopStore } from 'stores';
import { calcMileage } from 'helpers/unitCalculations';
import { ReactComponent as MilLamp } from 'assets/icons/milLamp.svg';
import { ReactComponent as ProtectLamp } from 'assets/icons/protectLamp.svg';
import { ReactComponent as AmberWarningLamp } from 'assets/icons/amberWarningLamp.svg';
import { ReactComponent as RedStopLamp } from 'assets/icons/redStopLamp.svg';

import { ReactComponent as MilLampOff } from 'assets/icons/milLampOff.svg';
import { ReactComponent as ProtectLampOff } from 'assets/icons/protectLampOff.svg';
import { ReactComponent as AmberWarningLampOff } from 'assets/icons/amberWarningLampOff.svg';
import { ReactComponent as RedStopLampOff } from 'assets/icons/redStopLampOff.svg';

import DeviceStore from 'stores/Classes/DeviceStore';

const { Text } = Typography;

const CarIndicator = styled.div`
  height: 100%;
  width: 8px;
  position: absolute;
  top: 0;
  padding: 0;
  background-color: ${(props) => props.color || 'initial'};
`;

const StyledBadge = ({ count, backgroundColor }) => (
  <Badge
    showZero={true}
    count={String(count)}
    style={{ backgroundColor }}
    overflowCount={1000000}
  />
);

const VehicleResolvedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

StyledBadge.propTypes = {
  count: PropTypes.any,
  backgroundColor: PropTypes.string,
};

const CarIDSection = styled.div`
  p {
    margin-bottom: 0px;
  }
`;

const WrapperVehiculeIconMobile = styled.div`
  display: flex;
  justiy-content: space-between;
  gap: 10px;
`;

export const renderLastTriggeredAtV2 = (car) => {
  const stringDate = new Date(car.last_triggered_at).toString();
  const outputString = _.get(stringDate.split('GMT'), 0, stringDate);

  return <p>{outputString}</p>;
};

export const renderIndicatorV2 = (car) => {
  if (parseInt(car.critical) > 0) {
    return <CarIndicator color={getPriorityTagColor(4)} />;
  }
  if (parseInt(car.major) > 0) {
    return <CarIndicator color={getPriorityTagColor(2)} />;
  }
  return <CarIndicator />;
};

export const renderVehicleResolvedV2 = (car) => {
  return (
    <VehicleResolvedContainer>
      {_.get(car, 'resolved') ? (
        <CheckCircleTwoTone style={{ fontSize: '200%' }} />
      ) : (
        <MinusCircleTwoTone style={{ fontSize: '200%' }} />
      )}
    </VehicleResolvedContainer>
  );
};

export const renderVehicleIdV2 = (car) => {
  return (
    <CarIDSection>
      <strong>{car.car_name}</strong>
      <p>
        {car.car_make} {car.car_model} {car.car_year}
      </p>
      <p>
        {`${Number(calcMileage(car.mileage_total).toFixed(1)).toReadable()}
        ${CurrentUserStore.user.displayedOdometer}`}
      </p>
      {!customFleets.wolfline.includes(ShopStore.currentShop.id) &&
        !customFleets.bmc.includes(ShopStore.currentShop.id) && (
          <p>{Math.round(car.engine_hours / 3600)} engine hours</p>
        )}
    </CarIDSection>
  );
};

export const renderEngineLightStatus = (car) => {
  const engineLightStatus = _.get(car, 'engine_light_status');
  const { device } = DeviceStore;
  const isTablet = device === 'tablet';
  const isMobile = device === 'mobile';

  const iconList = [
    {
      key: 'generalVehicleWarningLight',
      icon: AmberWarningLamp,
      iconOff: AmberWarningLampOff,
      tooltip: 'Engine Light',
    },
    {
      key: 'redStopLamp',
      icon: RedStopLamp,
      iconOff: RedStopLampOff,
      tooltip: isMobile ? 'Stop Lamp' : 'Red Stop Lamp',
    },
    {
      key: 'malfunctionLamp',
      icon: MilLamp,
      iconOff: MilLampOff,
      tooltip: isMobile ? 'Mil Lamp' : 'MIL Lamp (emissions)',
    },
    {
      key: 'amberWarningLamp',
      icon: AmberWarningLamp,
      iconOff: AmberWarningLampOff,
      tooltip: 'Check Engine Light',
    },
    {
      key: 'protectWarningLamp',
      icon: ProtectLamp,
      iconOff: ProtectLampOff,
      tooltip: isMobile ? 'Protect Lamp' : 'Engine Protect Lamp',
    },
  ];

  const resultIcons = iconList.map((item) => {
    const isActive = _.get(engineLightStatus, item.key);
    const IconComponent = isActive ? item.icon : item.iconOff;

    const IconElement = (
      <IconComponent
        key={item.key}
        width={isMobile ? 16 : 35}
        height={isMobile ? 16 : 35}
      />
    );
    return (
      <Tooltip key={item.key} title={item.tooltip}>
        {isTablet && (
          <Col span={12}>
            <div>{IconElement}</div>
          </Col>
        )}
        {isMobile && (
          <Row type="flex" align="middle" justify="space-between">
            <WrapperVehiculeIconMobile>
              <div>{IconElement}</div>
              <Text>{item.tooltip}</Text>
            </WrapperVehiculeIconMobile>
            <Col>
              <Text type={!isActive ? 'danger' : ''}>
                {isActive ? 'On' : 'Off'}
              </Text>
            </Col>
          </Row>
        )}
        {device === 'desktop' && isActive && <div>{IconElement}</div>}
      </Tooltip>
    );
  });

  return _.isEmpty(resultIcons) ? null : resultIcons;
};

export const renderSuggestedActionV2 = (car) => {
  const maxPriority = car.critical > 0 ? '4' : '2';
  let now = new Date();
  //Temp maxMajorIssueDate to always trigger the stale major message
  let maxMajorIssueDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 8
  );
  const issueCounts = null; // car.issueCounts;
  return (
    <p>
      {prescriptiveMaintenanceMessages(
        maxPriority,
        maxMajorIssueDate,
        false,
        issueCounts
      )}
    </p>
  );
};

export const renderIssuesV2 = (car) => {
  let criticalCount = car.critical || 0;
  let majorCount = car.major || 0;
  let minorCount = car.minor || 0;

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col xl={8} xs={6}>
          <StyledBadge
            count={criticalCount}
            backgroundColor={getPriorityTagColor(4)}
          />
        </Col>

        <Col xl={16} xs={18}>
          Critical
        </Col>
      </Row>

      <Row gutter={[8, 8]}>
        <Col xl={8} xs={6}>
          <StyledBadge
            count={majorCount}
            backgroundColor={getPriorityTagColor(2)}
          />
        </Col>

        <Col xl={16} xs={18}>
          Major
        </Col>
      </Row>

      <Row gutter={[8, 8]}>
        <Col xl={8} xs={6}>
          <StyledBadge
            count={minorCount}
            backgroundColor={getPriorityTagColor(1)}
          />
        </Col>

        <Col xl={16} xs={18}>
          Minor
        </Col>
      </Row>
    </>
  );
};
