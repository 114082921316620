import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { decorate } from 'mobx';

import { Col, Row, Button } from 'antd';
import WorkOrderLineItemDetails from './WorkOrderLineItemDetails';
import FormLegendCentered from 'components/Forms/FormLegendCentered';
import WorkOrderDetailsContext from '../../../containers/WorkOrder/WorkOrderDetailsContext';

import { WorkOrderLineItemProvider } from './WorkOrderLineItemContext';

class WorkOrderLineItems extends Component {
  static contextType = WorkOrderDetailsContext;

  static propTypes = {};

  workOrderLinesDetailsRefs = {};

  get currentLineItems() {
    const {
      state: { lineItems },
    } = this.context;
    return lineItems;
  }

  render() {
    const {
      updateLineItemHeader,
      onChangeLineItemField,
      onAddNewPart,
      onUpdateLaborItem,
      onUpdatePartItem,
      onUpdateMiscellaneousItem,
      onUpdateServiceItem,
      onRemoveLineItem,
      onSelectSystemCode,
      onSelectAsmCode,
      onAddLineItem,
      shopUsers,
      shopParts,
      currentCarIssues,
      onAddLabor,
      onAddPart,
      onAddIssue,
      onAddMiscellaneous,
      onRemoveMiscellaneous,
      onRemoveLabor,
      onRemovePart,
      onRemoveIssue,
      isDisabled,
      state: { screenSize },
    } = this.context;

    return (
      <Row gutter={[8, 8]}>
        <Col span={24}>
          {this.currentLineItems.map((lineItem) => {
            return (
              <div key={lineItem.key}>
                <WorkOrderLineItemProvider
                  value={{
                    lineItem,
                    shopUsers,
                    shopParts,
                    currentCarIssues,
                    onAddLabor,
                    onAddPart,
                    onAddIssue,
                    onAddMiscellaneous,
                    onRemoveMiscellaneous,
                    onRemoveLabor,
                    onRemovePart,
                    onRemoveIssue,
                    isDisabled,
                    screenSize,
                  }}
                >
                  <WorkOrderLineItemDetails
                    childRef={(ref) =>
                      (this.workOrderLinesDetailsRefs[lineItem.key] = ref)
                    }
                    onChangeServiceTask={(e, value) =>
                      updateLineItemHeader(e, value, lineItem)
                    }
                    onChangeField={(newVal, fieldName) =>
                      onChangeLineItemField(lineItem.key, newVal, fieldName)
                    }
                    onAddNewPart={(name) =>
                      onAddNewPart(name || '', lineItem.key)
                    }
                    onUpdateLaborItem={(labourKey, newVal, whatChanged) =>
                      onUpdateLaborItem(
                        lineItem.key,
                        labourKey,
                        newVal,
                        whatChanged
                      )
                    }
                    onUpdatePartItem={(partKey, newVal, changedField) =>
                      onUpdatePartItem(
                        lineItem.key,
                        partKey,
                        newVal,
                        changedField
                      )
                    }
                    onUpdateMiscellaneousItem={(
                      miscKey,
                      newVal,
                      changedField
                    ) =>
                      onUpdateMiscellaneousItem(
                        lineItem.key,
                        miscKey,
                        newVal,
                        changedField
                      )
                    }
                    onUpdateServiceItem={(serviceKey, newVal) =>
                      onUpdateServiceItem(lineItem.key, serviceKey, newVal)
                    }
                    onRemoveLineItem={() => onRemoveLineItem(lineItem.key)}
                    onSelectSystemCode={(systemCode) => {
                      onSelectSystemCode(lineItem.key, systemCode);
                    }}
                    onSelectAsmCode={(asmCode) => {
                      onSelectAsmCode(lineItem.key, asmCode);
                    }}
                  />
                </WorkOrderLineItemProvider>
              </div>
            );
          })}
        </Col>
        <Col span={24}>
          <FormLegendCentered>
            <Button
              type="primary"
              ghost
              onClick={() => onAddLineItem()}
              icon="plus-circle"
            >
              Add New Service Task
            </Button>
          </FormLegendCentered>
        </Col>
      </Row>
    );
  }
}

decorate(WorkOrderLineItems, {});

export default observer(WorkOrderLineItems);
