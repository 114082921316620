import React from 'react';
import { Button, Icon, Input, Table } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';

const StyledTable = styled(Table)`
  .ant-table-tbody {
    .ant-table-row {
      cursor: pointer;
    }

    .ant-table-row-selected {
      td {
        background-color: #e1effe;
      }
    }
  }
`;

class SelectContactTable extends React.Component {
  static propTypes = {
    initialSelectedRowKeys: PropTypes.array,
  };

  state = {
    selectedRowKeys: this.props.initialSelectedRowKeys || [],
    searchText: '',
    searchedColumn: '',
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys }, () => {
      if (this.props.onSelectChange) {
        this.props.onSelectChange(selectedRowKeys);
      }
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const { selectedRowKeys } = this.state;
    const {
      contacts
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      type: 'radio',
    };

    const columns = [
      {
        title: 'Vendor',
        dataIndex: 'name',
        key: 'name',
        ...this.getColumnSearchProps('name'),
      },
      {
        title: 'Distance From Location',
        dataIndex: 'distanceFromLocation',
        key: 'distanceFromLocation',
        render: (text) => {
          if (!text) return '--';
          return `${text} mi`;
        },
      },
      {
        title: 'Phone No.',
        dataIndex: 'phone',
        key: 'phone',
        render: (text) => {
          // if not a phone number, return empty string
          if (!text) return '--';
          return <a href={`tel:${text}`}>{text}</a>;
        },
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        render: (text) => {
          if (!text) return '--';
          return text;
        },
      },
      {
        title: 'Point of Contact',
        dataIndex: 'salesPerson',
        key: 'salesPerson',
        render: (text) => {
          if (!text) return '--';
          return text;
        },
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        key: 'notes',
        render: (text) => {
          if (!text) return '--';
          return text;
        },
      },
      { title: '', dataIndex: 'actions', key: 'actions' },
    ];

    return (
      <StyledTable
        rowKey={(record) => record.id}
        rowSelection={rowSelection}
        onRowClick={
          // select the row when clicked
          (record) => this.onSelectChange([record.id])
        }
        columns={columns}
        dataSource={contacts}
      />
    );
  }
}

SelectContactTable.propTypes = {
  contacts: PropTypes.array,
  onSelectChange: PropTypes.func,
  initialSelectedRowKeys: PropTypes.array,
};

export default SelectContactTable;
