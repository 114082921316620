import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, decorate } from 'mobx';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { List } from 'antd';

import { TableStore } from 'stores/abstract';
import { PitstopTableCacheStore } from 'stores/CacheStore';

class PitstopList extends Component {
  static propTypes = {
    tableStore: PropTypes.instanceOf(TableStore),
    id: PropTypes.string.isRequired,
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    fetchData: PropTypes.func.isRequired,
    rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    renderItem: PropTypes.func.isRequired,
    height: PropTypes.number,
    onRowClick: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    getTableStore: PropTypes.func,
    rowSelection: PropTypes.object,
    rows: PropTypes.array,
    limit: PropTypes.number,
    noInitialLoad: PropTypes.bool,
    rawData: PropTypes.bool,
    filter: PropTypes.object,
    sort: PropTypes.string,
    pagination: PropTypes.object,
  };

  static defaultProps = {
    noInitialLoad: false,
    rawData: false,
  };

  state = {
    editingRowKey: null,
  };

  get dataSource() {
    const { data } = this.props;
    return _.filter(data, Boolean);
  }

  async componentDidMount() {
    console.log(this.props);
    await this.load();

    if (this.props.data.length !== this.tableStore.data.length) {
      await this.props.fetchData(
        {
          offset: 0,
          limit: this.tableStore.limit || 10,
          filter: this.tableStore.filter || undefined,
          sort: this.tableStore.sort,
        },
        this.tableStore
      );
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      this.load();
    }

    if (
      !_.isEqual(this.props.searchValue, prevProps.searchValue) ||
      !_.isEqual(this.props.timeRange, prevProps.timeRange)
    ) {
      this.tableStore.reset();
      this.load();
    }

    // make sure the list is loaded
    if (!this.tableStore.loaded && !this.tableStore.pending) {
      this.load();
    }
  }

  get tableStore() {
    if (!PitstopTableCacheStore.data.has(this.props.id)) {
      PitstopTableCacheStore.setData(this.props.id, new TableStore());
    }
    return PitstopTableCacheStore.getData(this.props.id);
  }

  load = async () => {
    if (!this.tableStore.loaded) {
      let filter = { ...this.props.filter };

      let filters = {
        offset: 0,
        limit: this.props.limit || 10,
        sort: this.props.sort,
      };

      if (!_.isEmpty(filter)) {
        filters.filter = filter;
      }

      await this.props.fetchData(filters, this.tableStore);
    }

    if (this.props.getTableStore) {
      this.props.getTableStore(this.tableStore);
    }
  };

  handleListChange = async (page) => {
    const { pagination } = this.props;

    let offset = (page - 1) * pagination.limit;

    this.tableStore.reset();
    await this.props.fetchData(
      {
        offset: offset,
        limit: pagination.limit,
        sort: this.tableStore.sort,
        filter: this.tableStore.filter,
      },
      this.tableStore
    );
  };

  render() {
    const { renderItem, pagination } = this.props;

    const { count, offset, limit, loaded } = this.tableStore;

    const props = {
      dataSource: this.dataSource,
      loading: !loaded,
      renderItem,
      pagination: {
        pageSize: limit || this.props.limit,
        total: count,
        current: offset / (limit || this.props.limit) + 1,
        onChange: this.handleListChange,
        ...pagination,
      },
    };

    return (
      <>
        <List {...props} />
      </>
    );
  }
}

decorate(PitstopList, {
  tableStore: computed,
});

export default observer(PitstopList);
