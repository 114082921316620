import React from 'react';
import { Alert, Icon } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledAlert = styled(Alert)`
  && {
    background-color: #f6f9fd;
    border: 1px solid #d9e2ef;
    border-radius: 4px;

    .ant-alert-icon {
      color: #1890ff;
    }

    .ant-alert-message {
      color: black;
      font-weight: bold;
    }

    .ant-alert-description {
      color: black;
    }
  }
`;

const NotificationBanner = ({ message, onClose, hideClose = false }) => {
  return (
    <StyledAlert
      message={message}
      type="info"
      icon={<Icon type="info-circle" />}
      showIcon={true}
      closable={!hideClose}
      onClose={onClose}
    />
  );
};

NotificationBanner.propTypes = {
  message: PropTypes.any.isRequired,
  onClose: PropTypes.func,
};

NotificationBanner.defaultProps = {
  onClose: () => {},
};

export default NotificationBanner;
