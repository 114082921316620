import React from 'react';
import { Form, Button, TreeSelect, Popover, Row, Badge } from 'antd';
import styled from 'styled-components';

const WOFormFilterParts = ({
  optionStatus,
  optionAssigned,
  changeFilter,
  popoverVisible,
  togglePopoverVisibility,
  onClear,
  countFiltersSelected,
  form,
}) => {
  const WrapperButtons = styled(Row)`
    align-items: center;
    display: flex;
    justify-content: flex-end;

    button:first-child {
      margin-right: 16px;
    }
  `;

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        togglePopoverVisibility(false);
      }
    });
  };

  const clearData = () => {
    onClear();
    form.resetFields();
    togglePopoverVisibility(false);
  };

  const onChangeField = (key, value) => {
    changeFilter(key, value);
  };

  return (
    <Popover
      placement={'bottomLeft'}
      visible={popoverVisible}
      onVisibleChange={togglePopoverVisibility}
      content={
        <Form style={{ width: '78vw' }} onSubmit={handleSubmit}>
          <Form.Item label={'Filter by Status'}>
            {form.getFieldDecorator('status')(
              <TreeSelect
                treeData={optionStatus}
                onChange={(e) => {
                  onChangeField('status', e);
                }}
                allowClear={true}
                treeCheckable={true}
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                searchPlaceholder={'Please select Status'}
                style={{ width: '100%' }}
              />
            )}
          </Form.Item>

          <Form.Item label={'Filter by Assigned To'}>
            {form.getFieldDecorator('assignedTo')(
              <TreeSelect
                treeData={optionAssigned}
                onChange={(e) => {
                  onChangeField('assignedTo', e);
                }}
                allowClear={true}
                treeCheckable={true}
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                searchPlaceholder={'Please select Assigned'}
                style={{ width: '100%' }}
              />
            )}
          </Form.Item>

          <Form.Item>
            <WrapperButtons>
              <Button type="ghost" htmlType="reset" onClick={clearData}>
                Clear
              </Button>
              <Button
                type="primary"
                htmlType="button"
                onClick={(e) => {
                  togglePopoverVisibility(!popoverVisible);
                }}
              >
                Ok
              </Button>
            </WrapperButtons>
          </Form.Item>
        </Form>
      }
      title={'Filters'}
      trigger={'click'}
    >
      <Button type="primary" icon={'filter'} block>
        Filters{' '}
        {countFiltersSelected > 0 ? (
          <>
            <Badge
              count={countFiltersSelected >= 10 ? '9+' : countFiltersSelected}
              style={{
                marginLeft: 5,
                backgroundColor: '#fff',
                color: '#123FBF',
              }}
            />
          </>
        ) : (
          ''
        )}
      </Button>
    </Popover>
  );
};

export default Form.create()(WOFormFilterParts);
