import React from 'react';

interface MediaPreviewProps {
  media: string;
}

/**
 * @param props
 * @param props.media - The URL or base64 media to preview
 */
const MediaPreview: React.FC<MediaPreviewProps> = ({ media }) => {
  if (!media) {
    return null;
  }

  // if is a base64 pdf
  if (media.includes('application/pdf') || media.includes('.pdf')) {
    return (
      <embed
        id="media-preview"
        src={media}
        type="application/pdf"
        width="100%"
        style={{
          height: '1024px',
        }}
      />
    );
  }

  return (
    <div>
      <img
        id="media-preview"
        src={media}
        alt="Media Preview"
        style={{ width: '100%', height: 'auto' }}
      />
    </div>
  );
};

export default MediaPreview;
