import { Button, Card, Col, Row, Table, Select, Typography } from 'antd';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DeviceStore from 'stores/Classes/DeviceStore';

const { Option } = Select;
const { Text } = Typography;

const StyledHeaderRow = styled(Row)`
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 1rem;
      margin: 0;
      color: #1137ab;
      font-weight: 600;

      span {
        font-size: 0.8rem;
        font-weight: 400;
      }
    }
  }

  .date-range-wrapper {
    span {
      font-size: 0.9rem;
      font-weight: 400;
      margin-right: 10px;
    }
  }
`;

const StyledTableRow = styled(Row)`
  tbody tr:nth-child(odd) {
    background-color: #f7f7f7;
  }

  .ant-table-small {
    border: 0;
  }

  .ant-table-fixed-header .ant-table-scroll .ant-table-header {
    padding-bottom: 0;
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin-top: 20;
  }

  .ant-table-thead > tr > th .ant-table-header-column {
    font-weight: 600;
  }

  .ant-table-small > .ant-table-content > .ant-table-scroll {
    .link {
      color: #000000;
      font-weight: 400;
      &:hover {
        border-bottom: solid 1px #ccc;
        border-radius: 0;
      }
    }

    .status-open {
      color: #123fbf;

      .ant-btn-link {
        color: #123fbf;
        font-size: 1.1rem;
      }
    }

    .status-in-progress {
      color: #218730;

      .ant-btn-link {
        color: #218730;
        font-size: 1.1rem;
      }
    }

    .status-pending {
      color: #fe8f44;

      .ant-btn-link {
        color: #fe8f44;
        font-size: 1.1rem;
      }
    }

    .status-blocked {
      color: #ff202f;

      .ant-btn-link {
        color: #ff202f;
        font-size: 1.1rem;
      }
    }

    .status-completed {
      color: #5a5a5a;

      .ant-btn-link {
        color: #5a5a5a;
        font-size: 1.1rem;
      }
    }

    .status-waiting {
      color: #ff202f;

      .ant-btn-link {
        color: #ff202f;
        font-size: 1.1rem;
      }
    }

    .status-resolved {
      color: #333333;

      .ant-btn-link {
        color: #333333;
        font-size: 1.1rem;
      }
    }
  }
`;

const RowStatusCount = styled(Row)`
  span:first-child {
    color: ${({ color }) => color || '#00000'};
    font-size: 14px;
    font-weight: 600;
  }

  span:last-child {
    color: ${({ color }) => color || '#00000'};
    font-size: 24px;
  }
`;

const baseCount = {
  open: 0,
  in_progress: 0,
  resolved: 0,
  completed: 0,
  blocked_for_parts: 0,
};

const WorkOrderStatusByAssignedToWidget = ({
  onClickElement,
  workOrdersGroupedByStatusAndAssignedUser,
}) => {
  const [isFiltered, setIsFiltered] = useState(false);
  const [assignedUser, setAssignedUser] = useState([]);

  const [groupedTotal, setGroupedTotal] = useState(baseCount);

  const [currentTotal, setCurrentTotal] = useState({});

  const { device } = DeviceStore;

  useEffect(() => {
    onStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onStart() {
    const list = workOrdersGroupedByStatusAndAssignedUser
      .filter((val) => !isNaN(val.assignedToId))
      .map((item) => {
        return {
          value: item.assignedToId,
          label: item.assignedToName,
        };
      });

    setAssignedUser(list);
    let newTotal = {
      open: 0,
      in_progress: 0,
      resolved: 0,
      completed: 0,
      blocked_for_parts: 0,
    };

    workOrdersGroupedByStatusAndAssignedUser.forEach((item) => {
      newTotal.open += item.open || 0;
      newTotal.in_progress += item.in_progress || 0;
      newTotal.resolved += item.resolved || 0;
      newTotal.completed += item.completed || 0;
      newTotal.blocked_for_parts += item.blocked_for_parts || 0;
    });

    setGroupedTotal(newTotal);
  }

  const columns = [
    {
      title: '',
      dataIndex: 'assignedToName',
      key: 'assignedToName',
      render: (_, row) => {
        return (
          <Button
            onClick={onClickElement('user', row.assignedToName, row)}
            type="link"
            className="link"
          >
            {// if assignedToName lenght is greater than 12, then truncate it
            row.assignedToName.length > 12
              ? `${row.assignedToName.substring(0, 12)}...`
              : row.assignedToName}
          </Button>
        );
      },
    },
    {
      title: 'Open',
      dataIndex: 'open',
      key: 'open',
      align: 'center',
      className: 'status-open',
      render: (_, row) => {
        return (
          <Button
            onClick={onClickElement('status', 'open', row)}
            type="link"
            className="link"
          >
            {row.open || 0}
          </Button>
        );
      },
    },
    {
      title: 'In Progress',
      dataIndex: 'in_progress',
      key: 'in_progress',
      align: 'center',
      className: 'status-in-progress',
      render: (_, row) => {
        return (
          <Button
            onClick={onClickElement('status', 'in_progress', row)}
            type="link"
            className="link"
          >
            {row.in_progress || 0}
          </Button>
        );
      },
    },
    // {
    //   title: 'Blocked',
    //   dataIndex: 'blocked',
    //   key: 'blocked',
    //   align: 'center',
    //   className: 'blocked_for_parts',
    //   render: (_, row) => {
    //     return (
    //       <Button
    //         onClick={onClickElement('status', 'blocked_for_parts', row)}
    //         type="link"
    //         className="link"
    //       >
    //         {row.blocked_for_parts}
    //       </Button>
    //     );
    //   },
    // },
    {
      title: 'Blocked',
      dataIndex: 'blocked_for_parts',
      key: 'blocked_for_parts',
      align: 'center',
      className: 'status-blocked',
      render: (_, row) => {
        return (
          <Button
            onClick={onClickElement('status', 'blocked_for_parts', row)}
            type="link"
            className="link"
          >
            {row.blocked_for_parts || 0}
          </Button>
        );
      },
    },
    {
      title: 'Completed',
      dataIndex: 'completed',
      key: 'completed',
      align: 'center',
      className: 'status-completed',
      render: (_, row) => {
        return (
          <Button
            onClick={onClickElement('status', 'completed', row)}
            type="link"
            className="link"
          >
            {row.completed || 0}
          </Button>
        );
      },
    },
    // {
    //   title: 'Waiting',
    //   dataIndex: 'waiting_for_approval',
    //   key: 'waiting_for_approval',
    //   align: 'center',
    //   className: 'status-waiting',
    //   render: (_, row) => {
    //     return (
    //       <Button
    //         onClick={onClickElement('status', 'waiting_for_approval', row)}
    //         type="link"
    //         className="link"
    //       >
    //         {row.waiting_for_approval}
    //       </Button>
    //     );
    //   },
    // },
    {
      title: 'Resolved',
      dataIndex: 'resolved',
      key: 'resolved',
      align: 'center',
      className: 'status-resolved',
      render: (_, row) => {
        return (
          <Button
            onClick={onClickElement('status', 'resolved', row)}
            type="link"
            className="link"
          >
            {row.resolved || 0}
          </Button>
        );
      },
    },
  ];

  const RowStatus = ({
    open,
    in_progress,
    resolved,
    completed,
    blocked_for_parts,
    onClick,
  }) => {
    return (
      <>
        <RowStatusCount
          type="flex"
          key={1}
          align="middle"
          justify="space-between"
          color="#123FBF"
          onClick={onClickElement('onlystatus', 'open', null)}
        >
          <Text strong>Open</Text>
          <Text>{open}</Text>
        </RowStatusCount>
        <RowStatusCount
          type="flex"
          key={2}
          align="middle"
          justify="space-between"
          color="#098210"
          onClick={onClickElement('onlystatus', 'in_progress', null)}
        >
          <Text strong>In Progress</Text>
          <Text>{in_progress}</Text>
        </RowStatusCount>
        <RowStatusCount
          type="flex"
          key={3}
          align="middle"
          justify="space-between"
          color="#FF0000"
          onClick={onClickElement('onlystatus', 'blocked_for_parts', null)}
        >
          <Text strong>Blocked</Text>
          <Text>{blocked_for_parts}</Text>
        </RowStatusCount>
        <RowStatusCount
          type="flex"
          key={4}
          align="middle"
          justify="space-between"
          color="#727272"
          onClick={onClickElement('onlystatus', 'resolved', null)}
        >
          <Text strong>Resolved</Text>
          <Text>{resolved}</Text>
        </RowStatusCount>

        <RowStatusCount
          type="flex"
          key={5}
          align="middle"
          justify="space-between"
          color="#000000"
          onClick={onClickElement('onlystatus', 'completed', null)}
        >
          <Text strong>Completed</Text>
          <Text>{completed}</Text>
        </RowStatusCount>
      </>
    );
  };

  const onFilter = (e) => {
    setIsFiltered(true);
    if (e === 0) {
      const {
        open = 0,
        in_progress = 0,
        resolved = 0,
        completed = 0,
        blocked_for_parts = 0,
      } = groupedTotal || {};

      setCurrentTotal({
        open,
        in_progress,
        resolved,
        completed,
        blocked_for_parts,
      });
    } else {
      let currentData = workOrdersGroupedByStatusAndAssignedUser.filter(
        (item) => item.assignedToId === e
      );

      const {
        open = 0,
        in_progress = 0,
        resolved = 0,
        completed = 0,
        blocked_for_parts = 0,
      } = currentData[0] || {};

      setCurrentTotal({
        open,
        in_progress,
        resolved,
        completed,
        blocked_for_parts,
      });
      setTimeout(() => {
        onClickElement('user', currentData[0].assignedToName, currentData[0])();
      }, 300);
    }
  };

  return (
    <Card>
      <StyledHeaderRow gutter={[16, 16]}>
        <Col className="card-header" span={24}>
          <h2>
            Work Order Assigned{' '}
            <span>
              (
              {device === 'mobile'
                ? 'Click Labels to filter'
                : 'Click on any name or number to filter by'}
              )
            </span>
          </h2>
        </Col>
      </StyledHeaderRow>
      {device === 'mobile' ? (
        <>
          <Select
            style={{ width: '100%' }}
            onChange={onFilter}
            placeholder="Select assigned to"
          >
            {assignedUser.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>

          <RowStatus
            open={isFiltered ? currentTotal.open : groupedTotal.open}
            in_progress={
              isFiltered ? currentTotal.in_progress : groupedTotal.in_progress
            }
            resolved={
              isFiltered ? currentTotal.resolved : groupedTotal.resolved
            }
            completed={
              isFiltered ? currentTotal.completed : groupedTotal.completed
            }
            blocked_for_parts={
              isFiltered
                ? currentTotal.blocked_for_parts
                : groupedTotal.blocked_for_parts
            }
          />
        </>
      ) : (
        <>
          <StyledTableRow gutter={[16, 16]}>
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={workOrdersGroupedByStatusAndAssignedUser}
                pagination={false}
                size="small"
                scroll={{ y: 200 }}
              />
            </Col>
          </StyledTableRow>
        </>
      )}
    </Card>
  );
};

WorkOrderStatusByAssignedToWidget.propTypes = {
  onClickElement: PropTypes.func,
  workOrdersGroupedByStatusAndAssignedUser: PropTypes.arrayOf(PropTypes.object)
    .isRequired,
};

WorkOrderStatusByAssignedToWidget.defaultProps = {
  onClickElement: () => {},
  workOrdersGroupedByStatusAndAssignedUser: [],
};

export default WorkOrderStatusByAssignedToWidget;
