import React from 'react';
import styled from 'styled-components';
import { Tag } from 'antd';

export function PitStopTagStatus(props) {
  const TagStatus = styled(Tag).attrs({ as: 'span' })`
    color: ${({ color }) => color || 'inherit'};
    border: 1px solid ${({ color }) => color || 'inherit'};
    border-radius: 100px;
    font-size: 8px;
    padding: 3px 8px;
    line-height: 1;
    height: 16px;
  `;

  const { color, content } = props;
  return (
    <TagStatus color={color}>
      <>{content}</>
    </TagStatus>
  );
}
