import React from 'react';

import { Icon, Typography } from 'antd';
import './PitstopBadge.less';

interface PitstopBadgeProps {
  text: string;
  icon: string;
  color: string;
}

/**
 * @param props
 * @param props.text - The text to display
 * @param props.icon - The icon to display
 * @param props.color - The color of the badge
 */
const PitstopBadge: React.FC<PitstopBadgeProps> = ({ text, icon, color }) => {
  return (
    <div
      className="badge"
      style={{
        borderColor: color,
      }}
    >
      {/* @ts-ignore */}
      <Icon type={icon} style={{ color }} />
      <Typography.Text
        style={{
          color,
        }}
      >
        {text}
      </Typography.Text>
    </div>
  );
};

export default PitstopBadge;
