import React from 'react';
import styled from 'styled-components';
import { Col, Row, Typography, Card, Button, Select, List } from 'antd';
import { AppStore, WorkOrderStore } from 'stores';
import { Logger } from 'stores/Classes';

import moment from 'moment';

import _ from 'lodash';

const { Title, Text } = Typography;
const { Option } = Select;

const WrapperCard = styled(Card)`
  border-radius: 10px !important;
  margin-bottom: 20px !important;
  .ant-card-actions {
    background: none;
    border: none;
  }

  .ant-card-body {
    padding: 13px 10px 13px 13px;
  }

  .ant-card-actions .anticon {
    color: #123fbf;
  }
`;

const WrapperCardInfo = styled.div`
  display: flex;
  align-items: flex-end;

  svg {
    margin-bottom: 3px;
  }
`;

const TitleCard = styled(Title).attrs({ as: 'h2' })`
  color: #222222;
  font-size: 18px;
  flex: 1;
  font-weight: 500;
  line-height: 1;
  margin-right: 4px;
  margin-bottom: 0;
`;

const ColText = styled(Col).attrs({ as: 'div' })`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const WrapperServices = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const CardStatusSelect = ({ options, onChange, data }) => {
  const colors = {
    open: '#123FBF',
    blocked_for_parts: '#FF0000',
    completed: '#222222',
    resolved: '#727272',
  };

  const DropButton = styled(Select)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: white;
    border-color: ${({ status }) => colors[status] || 'inherit'};
    color: ${({ status }) => colors[status] || 'inherit'};
    padding: 5px 10px;
    border-radius: 6px;

    .ant-select-selection {
      border-color: ${({ status }) => colors[status] || 'inherit'};
    }

    .ant-select-selection-selected-value {
      color: ${({ status }) => colors[status] || 'inherit'};
    }
  `;

  return (
    <>
      <DropButton
        defaultValue={data.status}
        status={data.status}
        className={`status-select ${data.status}`}
        style={{ width: 180 }}
        onChange={(evt) => onChange(evt, data)}
        onClick={(evt) => evt.stopPropagation()}
        disabled={data.migrate_source === 'TMT'}
      >
        {options.map((item) => (
          <Option key={item.key} value={item.key}>
            {item.value}
          </Option>
        ))}
      </DropButton>
    </>
  );
};

const CardAssignedToSelect = ({ options, onChange, data }) => {
  const DropButton = styled(Select)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: white;
    border-radius: 6px;
    margin-top: 7px;
    padding: 1px;
    width: 100%;
  `;

  return (
    <>
      <Text strong>Assigned To</Text>
      <DropButton
        defaultValue={data.assigned_to}
        status={data.status}
        className={`status-select ${data.status}`}
        onChange={(evt) => onChange(evt, data)}
        onClick={(evt) => evt.stopPropagation()}
      >
        {options.map((item) => (
          <Option key={item.key} value={item.key}>
            {item.value}
          </Option>
        ))}
      </DropButton>
    </>
  );
};

const CardListServices = ({ data }) => {
  const ListItem = styled(List.Item)`
    border-bottom: none !important;
  `;

  const getTitle = (woLine) => {
    const hasVmrsInfo = woLine.vmrs_asm_code && woLine.vmrs_asm_text;
    let title;
    if (hasVmrsInfo) {
      title = `${woLine.vmrs_asm_code} - ${woLine.vmrs_asm_text}`;
    } else {
      title = _.get(woLine, 'title', '');
    }
    return title;
  };
  return data && data.length ? (
    <WrapperServices>
      <Text strong>Service tasks</Text>
      <List
        bordered={false}
        size="small"
        dataSource={data ? data.map(getTitle) : []}
        renderItem={(item) => <ListItem>{item}</ListItem>}
      />
    </WrapperServices>
  ) : (
    'N/A'
  );
};

const CardCreatedBy = ({ data, options }) => {
  const getUser = () => {
    const createdUser = options.filter((item) => item.key === data.created_by);

    let user = '--';

    if (createdUser[0]) {
      user = createdUser[0].value;
    }

    return user;
  };
  return (
    <>
      <Text strong>Created at</Text>
      <Text>{moment(data.created_at).format('ll')}</Text>
      <Text>{getUser()}</Text>
    </>
  );
};

export const WOCardItemPart = ({
  data,
  toggleCard,
  isExpanded,
  optionStatus,
  changeStatus,
  optionAssigned,
  changeAssigned,
  onItemClick,
  history,
}) => {
  const onDownload = async () => {
    try {
      AppStore.addLoading('Getting Work Order Report');
      await WorkOrderStore.downloadWorkOrder(data.id);
    } catch (error) {
      Logger.error(error);
      AppStore.addError(
        'Unknown error to generate the Work Order. Please try again later'
      );
    } finally {
      AppStore.removeLoading('Getting Work Order Report');
    }
  };

  return (
    <WrapperCard>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={12}>
          <WrapperCardInfo>
            <TitleCard>{data.id}</TitleCard>
          </WrapperCardInfo>
        </Col>
        <Col span={12}>
          <CardStatusSelect
            options={optionStatus}
            onChange={changeStatus}
            data={data}
          />
        </Col>
      </Row>
      <>
        <CardAssignedToSelect
          options={optionAssigned}
          onChange={changeAssigned}
          data={data}
        ></CardAssignedToSelect>
        <Row
          type="flex"
          align="middle"
          justify="space-between"
          style={{ marginTop: 8 }}
        >
          <ColText span={12}>
            <Text strong>Unit ID</Text>
            <Text>{data.car_name || 'N/A'}</Text>
          </ColText>
          <ColText span={12}>
            <CardCreatedBy data={data} options={optionAssigned} />
          </ColText>
        </Row>
        {data.work_order_lines && data.work_order_lines.length > 0 && (
          <CardListServices data={data.work_order_lines} />
        )}

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Button
              onClick={onDownload}
              type="ghost"
              size={'small'}
              style={{ flex: 1 }}
              block
            >
              Download order
            </Button>
          </Col>
          <Col span={12}>
            <Button
              onClick={(e) => history.push(`/work-order/${data.id}/edit`)}
              type="primary"
              size={'small'}
              style={{ flex: 1 }}
              block
            >
              See More Details
            </Button>
          </Col>
        </Row>
      </>
    </WrapperCard>
  );
};
