import React from 'react';
import { Card, Col, Row, Spin, Typography } from 'antd';
import PropTypes from 'prop-types';
import { ShopStore } from 'stores';
import { customFleets } from 'shared';

import './OverdueDueSoonCounterStyles.less';

const { Text } = Typography;

const PMCountWidget = ({
  isLoadingPMCounts,
  pmCounts,
  handlePMsCountClick,
}) => {
  return (
    <Card>
      <div className="card-title">
        <Text strong>PMs</Text>
      </div>
      <Row style={{ marginTop: 15 }} gutter={[16, 16]}>
        <Col span={24}>
          <div
            className="safety-inspection-col"
            style={{
              height: customFleets.cardinalCourier.includes(
                ShopStore.currentShop.id
              )
                ? 'auto'
                : '255px',
            }}
          >
            {isLoadingPMCounts ? (
              <Spin tip="Loading..." />
            ) : (
              <>
                <div
                  className="clickable-section"
                  onClick={() => handlePMsCountClick('overdue')}
                >
                  <div className="overdue item">
                    <span className="count">{pmCounts.overdue_count}</span>
                    <span className="label">Overdue</span>
                  </div>
                </div>
                <div
                  className="clickable-section"
                  onClick={() => handlePMsCountClick('dueSoon')}
                >
                  <div className="due-soon item">
                    <span className="count">{pmCounts.due_soon_count}</span>
                    <span className="label">Due Soon</span>
                  </div>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

PMCountWidget.propTypes = {
  isLoadingPMCounts: PropTypes.bool,
  pmCounts: PropTypes.shape({
    overdue_count: PropTypes.any,
    due_soon_count: PropTypes.any,
  }),
  handlePMsCountClick: PropTypes.func,
};

PMCountWidget.defaultProps = {
  isLoadingPMCounts: false,
  pmCounts: {
    overdue_count: 0,
    due_soon_count: 0,
  },
  handlePMsCountClick: () => {},
};

export default PMCountWidget;
