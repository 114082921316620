import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import styled from 'styled-components';
import { PitstopButton } from 'shared';
import { ShopStore } from 'stores';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';
import UploadImageModal from './modals/UploadImageModal';
import WorkOrderTable from './WorkOrderTable';
import { webServiceProvider } from 'shared';
import { AppStore } from 'stores';
import DeviceStore from 'stores/Classes/DeviceStore';

const Wrapper = styled.div`
  position: relative;
`;

const UploadButton = styled(Button)`
  background-color: #fff;
  border: 1px solid #1890ff;
  color: #1890ff;
  &:hover {
    background-color: #ecf6fd;
    border-color: #1890ff;
  }
  &:focus,
  &:active {
    background-color: #fff;
    border-color: #1890ff;
  }
`;

class WorkOrderPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      showUploadButton: false,
    };
  }

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleUpload = async (files) => {
    AppStore.addLoading('Uploading files...');
    const fileUrls = [];

    const uniqueFiles = files
      .map((file) => file.originFileObj || file)
      .filter((file, index, array) => {
        const identifier = file.name + file.size;
        return (
          array.findIndex((item) => item.name + item.size === identifier) ===
          index
        );
      });

    for (const file of uniqueFiles) {
      const fd = new FormData();
      fd.append('singleInputFileName', file);
      fd.append('bucketName', 'work-orders-scan');

      try {
        const data = await webServiceProvider.postFormData(
          'dashboard/v1/uploadimage?storageType=work-orders-scan',
          fd
        );
        if (data.fileUrl) {
          fileUrls.push(data.fileUrl);
        }
      } catch (e) {
        AppStore.addError('Image upload failed!');
      } finally {
        AppStore.removeLoading('Uploading files...');
      }
    }
    AppStore.addSuccess('Files has been uploaded');
    this.setState({ isModalVisible: false });
    // Use s3 fileUrls array as needed
    console.log(fileUrls);
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  state = {};

  disposer = observe(ShopStore.currentShop, 'id', async () => {
    if (ShopStore.isAdmin()) return;
    return ShopStore.getShopNotifications(ShopStore.currentShop.id);
  });

  navigateToAddWorkOrder = () => {
    this.props.history.push('/work-order/add');
  };

  EmailReportModalId = 'EmailReportModalId';

  bulkEdit = {
    default: 0,
    maintenanceReport: 1,
    vehicleIssue: 2,
  };

  selectedBulkEdit = 0;

  render() {
    const columns = [
      'invoice_number',
      'id_car',
      'status',
      'priority',
      'work_order_lines',
      'created_by',
      'assigned_to',
      'total_costs',
      'actions',
    ];
    const { showUploadButton } = this.state;
    const { device } = DeviceStore;
    return (
      <Fragment>
        <Wrapper>
          <div
            style={{
              position: device === 'mobile' ? 'relative' : 'absolute',
              right: '0px',
            }}
          >
            <Row gutter={16}>
              {showUploadButton ? (
                <>
                  <Col span={14}>
                    <UploadButton icon="upload" onClick={this.showModal}>
                      Upload Work Order
                    </UploadButton>
                    <UploadImageModal
                      isVisible={this.state.isModalVisible}
                      onUpload={this.handleUpload}
                      onCancel={this.handleCancel}
                    />
                  </Col>
                  <Col span={10}>
                    <PitstopButton
                      icon="plus"
                      onClick={this.navigateToAddWorkOrder}
                    >
                      Add New
                    </PitstopButton>
                  </Col>
                </>
              ) : (
                <Col span={24} style={{ textAlign: 'right' }}>
                  <PitstopButton
                    icon="plus"
                    onClick={this.navigateToAddWorkOrder}
                  >
                    Add New
                  </PitstopButton>
                </Col>
              )}
            </Row>
          </div>
          <h1>
            {device === 'mobile' ? 'Work Order Mgmt' : 'Work Order Management'}
          </h1>
        </Wrapper>

        <WorkOrderTable
          isSelectionEnabled={false}
          isEditable={false}
          columnKeys={columns}
          history={this.props.history}
        />
      </Fragment>
    );
  }
}

export default observer(WorkOrderPage);
