import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, decorate } from 'mobx';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import { Empty, message, Table } from 'antd';

import { TableStore } from 'stores/abstract';
import { CarStore } from 'stores';
import { PitstopTableCacheStore } from 'stores/CacheStore';

import { columnTextFilterProps } from './columnTextFilterProps';
import { titleWithSearch } from './titleWithSearch';
import {
  editableGridComponents,
  getEditableGridActionColumn,
} from './EditableGrid';

const TableContainer = styled(Table)`
  display: grid;
  overflow: auto;

  .ant-table-tbody tr {
    cursor: pointer;
  }

  .ant-table-pagination.ant-pagination {
    text-align: center;
    float: none;
  }

  @media (max-width: 768px) {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 8px;
    }
  }
`;

// const PaginationContainer = styled(Pagination)`
//   text-align: center;
//   float: none;
// `;

class PitstopTable extends Component {
  static propTypes = {
    tableStore: PropTypes.instanceOf(TableStore),
    id: PropTypes.string.isRequired,
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    fetchData: PropTypes.func.isRequired,
    rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    expandedRowRender: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    height: PropTypes.number,
    onRowClick: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    getTableStore: PropTypes.func,
    rowSelection: PropTypes.object,
    rows: PropTypes.array,
    limit: PropTypes.number,
    noInitialLoad: PropTypes.bool,
    rawData: PropTypes.bool,
    filter: PropTypes.object,
    sort: PropTypes.string,
    expandIconAsCell: PropTypes.bool,
    expandIconColumnIndex: PropTypes.number,
    pagination: PropTypes.object,
    searchValue: PropTypes.string,
    timeRange: PropTypes.any,
    hasNotification: PropTypes.bool,
    onClickNotification: PropTypes.func,
    scroll: PropTypes.object,
    hideTopPagination: PropTypes.bool,
    components: PropTypes.object,
  };

  static defaultProps = {
    noInitialLoad: false,
    rawData: false,
    scroll: {},
  };

  state = {
    editingRowKey: null,
  };

  get dataSource() {
    const { data } = this.props;

    return _.filter(data, Boolean);
  }

  async componentDidMount() {
    await this.load();

    // reload current state if there is difference amount of data (delete record)
    if (this.props.data.length !== this.tableStore.data.length) {
      await this.props.fetchData(
        {
          offset: 0,
          limit: this.tableStore.limit || 10,
          filter: this.tableStore.filter || undefined,
          sort: this.tableStore.sort,
        },
        this.tableStore
      );
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.id !== prevProps.id) {
      this.load();
    }

    if (
      !_.isEqual(this.props.searchValue, prevProps.searchValue) ||
      !_.isEqual(this.props.timeRange, prevProps.timeRange)
    ) {
      this.tableStore.reset();
      this.load();
    }

    // make sure the table is loaded
    if (!this.tableStore.loaded && !this.tableStore.pending) {
      this.load();
    }
  }

  get tableStore() {
    if (!PitstopTableCacheStore.data.has(this.props.id)) {
      PitstopTableCacheStore.setData(this.props.id, new TableStore());
    }

    return PitstopTableCacheStore.getData(this.props.id);
  }

  load = async () => {
    if (!this.tableStore.loaded) {
      let filter = { ...this.props.filter };

      let filters = {
        offset: 0,
        limit: this.props.limit || 10,
        sort: this.props.sort,
      };

      if (!_.isEmpty(filter)) {
        filters.filter = filter;
      }

      await this.props.fetchData(filters, this.tableStore);
    }

    if (this.props.getTableStore) {
      this.props.getTableStore(this.tableStore);
    }
  };

  handleTableChange = async (pagination, filter, sort) => {
    const { columns } = this.props;

    let column = null;
    let offset = (pagination.current - 1) * pagination.pageSize;

    if (sort.columnKey) {
      let sortString = sort.columnKey;
      const sortColumn = _.filter(
        columns,
        (el) => sort.columnKey === (el.sortLabel || el.dataIndex || el.key)
      )[0];

      if (!sortColumn) {
        sortString =
          sortColumn.sortLabel || sortColumn.dataIndex || sortColumn.key;
      }

      if (!sort.order) {
        column = undefined;
      } else {
        column = sort.order === 'ascend' ? `${sortString}` : `-${sortString}`;
      }
    }

    // eslint-disable-next-line eqeqeq
    // if (this.tableStore.sort != column) {
    //   offset = 0;
    // }

    this.tableStore.reset();

    await this.props.fetchData(
      {
        offset: offset,
        limit: pagination.pageSize,
        sort: column,
        filter: {
          ...this.tableStore.filter,
          ...this.props.filter,
          ...filter,
        },
      },
      this.tableStore
    );
  };

  getSortOrder = (column) => {
    const tableStore = this.tableStore;
    if (!tableStore.sort) {
      return false;
    }
    const index = tableStore.sort.indexOf(column);
    if (index === -1) {
      return false;
    } else if (index === 0) {
      return 'ascend';
    } else {
      return 'descend';
    }
  };

  get tableColumns() {
    const { columns, rowKey, onRowClick } = this.props;

    const antdGridColumns = _.map(columns, (c) => {
      let onCell = (record, rowIndex) => {
        if (c.onCell) {
          c.onCell(record, rowIndex);
        }
        let onCellObject = {};
        if (c.editable) {
          onCellObject = {
            record,
            inputType: c.inputType,
            dataIndex: c.dataIndex,
            selectOptions: c.selectOptions,
            title: c.title,
            editing: record[rowKey] === this.state.editingRowKey,
          };
        }
        if (onRowClick && c.rowClick !== false) {
          onCellObject = {
            ...onCellObject,
            onClick: (ev) => {
              ev.preventDefault();
              onRowClick(record, ev);
            },
          };
        }
        return onCellObject;
      };

      let columnFilterProps = columnTextFilterProps(
        c.dataIndex || c.key,
        c.title
      );

      return {
        ...c,
        key: c.key || c.dataIndex,
        ...(c.sorter && {
          sortOrder: (() =>
            this.getSortOrder(c.sortLabel || c.dataIndex || c.key))(),
        }),
        title: columns.some((c) => c.filter)
          ? titleWithSearch({
              ...c,
              onChange: (e) => {
                columnFilterProps.dropDownComponent.props.onChange(e);
              },
              tableStore: this.tableStore,
            })
          : c.title,
        ...(c.filter && columnFilterProps),
        onCell: onCell,
      };
    });
    // Is Editable
    if (columns.some((c) => c.editable)) {
      antdGridColumns.push(
        getEditableGridActionColumn({
          isEditing: (row) => row[rowKey] === this.state.editingRowKey,
          onDelete: async (row) => {
            try {
              await this.props.onDelete(row);
              message.success('Deleted successfully');
              this.props.fetchData({}, this.tableStore);
            } catch (e) {
              message.error('Delete failed');
              // eslint-disable-next-line no-console
              console.error(e);
            }
          },
          onCancel: () => this.setState({ editingRowKey: null }),
          onEdit: (row) => this.setState({ editingRowKey: row[rowKey] }),
          onSave: async (row, formValue) => {
            let changes = Object.keys(formValue).reduce((changed, key) => {
              return formValue[key] !== row[key]
                ? { ...changed, [key]: formValue[key] }
                : changed;
            }, {});
            if (Object.keys(changes).length) {
              changes[rowKey] = row[rowKey];
            } else {
              changes = null;
            }
            await this.props.onEdit({
              changes,
              newValue: changes ? { ...row, ...changes } : row,
              oldValue: row,
            });
            this.setState({ editingRowKey: null });
          },
          hasNotification: this.props.hasNotification,
          onClickNotification: this.props.onClickNotification,
        })
      );
    }
    return antdGridColumns;
  }

  render() {
    if (CarStore.demo === false) return <Empty />;

    const {
      columns,
      expandedRowRender,
      height,
      rowKey,
      rowSelection,
      expandIconAsCell,
      expandIconColumnIndex,
      pagination,
      scroll,
      components,
    } = this.props;

    const { count, offset, limit, loaded } = this.tableStore;

    const props = {
      columns: this.tableColumns,
      dataSource: this.dataSource,
      loading: !loaded,
      rowKey,
      pagination: {
        pageSize: limit || this.props.limit,
        total: count,
        current: offset / (limit || this.props.limit) + 1,
        ...pagination,
      },
      onChange: this.handleTableChange,
      scroll: { x: true, ...scroll },
      expandIconAsCell,
      expandIconColumnIndex,
      components: components || {},
    };

    // Is Editable
    if (columns.some((c) => c.editable)) {
      props.components = editableGridComponents;
    }

    // also add components from props
    props.components = {
      ...props.components,
      ...this.props.components,
    };

    if (expandedRowRender) {
      props.expandedRowRender = expandedRowRender;
      delete props['scroll'];
    }

    if (!_.isNil(rowSelection)) {
      props.rowSelection = rowSelection;
    }
    if (height) {
      props.scroll = { ...props.scroll, y: height };
    }

    // const PitstopPagination = (paginationObj) => {
    //   return (
    //     <PaginationContainer
    //       {...paginationObj}
    //       onChange={(page, pageSize) => {
    //         this.handleTableChange({
    //           current: page,
    //           pageSize,
    //         }, {}, {});
    //       }}
    //       onShowSizeChange={(current, pageSize) => {
    //         this.handleTableChange({
    //           current,
    //           pageSize,
    //         }, {}, {});
    //       }}
    //     />
    //   );
    // };

    return (
      <>
        {/* {!this.props.hideTopPagination &&
        <PitstopPagination
          {...props.pagination}
        />
      } */}
        <TableContainer {...props} />
      </>
    );
  }
}

decorate(PitstopTable, {
  tableStore: computed,
});

export default observer(PitstopTable);
