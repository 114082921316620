import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import { Select } from 'antd';

import { ShopStore, CurrentUserStore, ReportsDataStore } from 'stores';
import { UserRoles } from 'stores/Classes/UserObject';
import DeviceStore from 'stores/Classes/DeviceStore';

const Option = Select.Option;
const { device } = DeviceStore;

const Container = styled.div`
  &&& {
    margin: 10px 0px;
    display: flex;

    --select-width: ${device === 'mobile' ? '100%' : '20rem'};

    .ant-select {
      width: var(--select-width);
    }

    p {
      margin: auto 0px;
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      .ant-select {
        width: 100%;
      }
    }

    @media only screen and (min-width: 768px) {
      p {
        text-align: ${(props) => props.pathname === '/location' && 'right'};
        width: ${(props) => (props.pathname === '/location' ? '12em' : '6em')};
        margin-right: ${(props) => props.pathname === '/location' && '1em'};
      }
    }
  }
`;

class DealershipChooser extends Component {
  static propTypes = {
    location: PropTypes.object,
  };

  componentDidMount() {
    if (CurrentUserStore.user.role !== UserRoles.customer) {
      if (!ShopStore.loaded && !ShopStore.pending) {
        ShopStore.getShops();
      }
    }
  }

  handleDealershipChange = (newDealership) => {
    ShopStore.setCurrentShop(newDealership);
    ReportsDataStore.reload();
  };

  render() {
    if (!['admin', 'dealership'].includes(CurrentUserStore.user.role))
      return <></>;
    return (
      <Container pathname={this.props.location.pathname}>
        <p>Select Fleet:</p>

        <div>
          <Select
            showSearch
            placeholder="Select a Fleet"
            optionFilterProp="children"
            loading={!ShopStore.loaded}
            onChange={this.handleDealershipChange}
            value={parseInt(ShopStore.currentShop.id)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {_.map(Array.from(ShopStore.data.values()), (shop) => (
              <Option key={shop.id} value={shop.id}>
                {shop.name}
              </Option>
            ))}
          </Select>
        </div>
      </Container>
    );
  }
}

export default withRouter(observer(DealershipChooser));
