import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, decorate } from 'mobx';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Col, Row, Typography, Select, InputNumber, Icon, Button } from 'antd';
import TotalAmount from 'components/TotalAmount';
import IconWithBorders from 'components/Icons/IconWithBorder';
import FlexRow from 'components/General/FlexRow';
import FlexColum from 'components/General/FlexColum';
import WorkOrderLineItemContext from '../LineItems/WorkOrderLineItemContext';

const { Text } = Typography;
const { Option } = Select;

const NoFoundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

class WorkOrderLineItemPartPanelItem extends Component {
  static contextType = WorkOrderLineItemContext;

  static propTypes = {
    childRef: PropTypes.func,
    part: PropTypes.object,
    onUpdateTotal: PropTypes.func,
    onRemoveItem: PropTypes.func,
    onAddNewPart: PropTypes.func.isRequired,
    shopParts: PropTypes.array,
    onUpdateLocalItem: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    displayIcon: PropTypes.bool,
  };

  state = {
    currentSearchPart: '',
  };

  partValue = '';

  get localShopParts() {
    return this.props.shopParts || [];
  }

  get partsOptions() {
    return this.localShopParts.map((part, index) => {
      let displayText =
        `${part.number} ${part.description}`.trim() || part.name;
      if (displayText === 'null null') {
        displayText = part.name;
      }
      const screenSize = this.context.screenSize;
      let limitFactor = 20;
      if (screenSize <= 575) {
        limitFactor = 10;
      } else if (screenSize <= 767) {
        limitFactor = 15;
      } else if (screenSize <= 991) {
        limitFactor = 20;
      } else if (screenSize <= 1199) {
        limitFactor = 30;
      } else if (screenSize <= 1600) {
        limitFactor = 40;
      } else {
        limitFactor = 50;
      }
      const trimedText = displayText.length > limitFactor
        ? displayText.substring(0, limitFactor) + '...'
        : displayText;
      return (
        <Option key={index} value={part.id} label={trimedText}>
          {displayText}
        </Option>
      );
    });
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  get totalAmount() {
    const cost = parseFloat(this.props.part.cost) || 0;
    const markup = parseFloat(this.props.part.markup) || 0;
    const amount = parseFloat(this.props.part.amount) || 0;

    return (cost + (cost * markup) / 100) * amount;
  }

  onChangeAmount = (newVal, fieldName) => {
    if (this.props.onUpdateLocalItem) {
      this.props.onUpdateLocalItem(newVal, fieldName);
    }

    if (this.props.onUpdateTotal) {
      this.props.onUpdateTotal(this.totalAmount);
    }
  };

  onSearchPart = (value) => {
    this.setState({ currentSearchPart: value });
  };

  onClickAddPart = () => {
    if (this.props.onAddNewPart) {
      this.props.onAddNewPart(this.state.currentSearchPart || '');
    }
  };

  onSelectPart = (value, fieldName) => {
    if (this.props.onUpdateLocalItem) {
      this.props.onUpdateLocalItem(value, fieldName);
    }
  };

  onRemoveItem = (event, itemKey) => {
    if (this.props.onRemoveItem) {
      this.props.onRemoveItem(event, itemKey);
    }
    if (this.props.onUpdateTotal) {
      this.props.onUpdateTotal(this.totalAmount);
    }
  };

  render() {
    const { screenSize } = this.context;

    const filterOption = (input, option) => {
      return (
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    };

    const noPartFound = this.state.currentSearchPart ? (
      <NoFoundContainer>
        <Button type="link" icon="plus" onClick={this.onClickAddPart}>
          Add {this.state.currentSearchPart}
        </Button>
      </NoFoundContainer>
    ) : (
      <NoFoundContainer>
        <Icon style={{ fontSize: '24px' }} type="inbox" />
        <Text level={4}>No Data</Text>
      </NoFoundContainer>
    );

    this.partValue =
      this.props.part?.vehiclePart?.source === 'TMT'
        ? this.props.part.vehiclePart.name +
          ': ' +
          this.props.part.vehiclePart.vmrs_component_code
        : this.props.part.id_vehicle_part;

    return (
      <Row style={{ margin: 0 }} gutter={[8, 8]}>
        <Col xs={24} sm={11}>
          <FlexRow>
            {this.props.displayIcon ? (
              <FlexColum
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginRight: 10,
                }}
              >
                <IconWithBorders icon="tool" />
              </FlexColum>
            ) : (
              <FlexColum
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginRight: screenSize <= 575 ? 0 : 10,
                }}
              >
                <div
                  style={{
                    width: screenSize <= 575 ? 0 : 35,
                  }}
                />
              </FlexColum>
            )}
            <FlexColum style={{ flex: 1 }}>
              <Text strong>Part</Text>
              <Select
                value={this.partValue}
                placeholder="Select a Part"
                onChange={(value) =>
                  this.onSelectPart(value, 'id_vehicle_part')
                }
                onSearch={(value) => this.onSearchPart(value)}
                showSearch
                optionFilterProp="children"
                optionLabelProp='label'
                filterOption={filterOption}
                notFoundContent={noPartFound}
                disabled={this.props.isDisabled}
              >
                {this.partsOptions}
              </Select>
            </FlexColum>
          </FlexRow>
        </Col>
        <Col xs={6} sm={3}>
          <Text strong>Quantity:</Text>
          <InputNumber
            className="part-quantity-input"
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%' }}
            value={this.props.part.amount}
            onChange={(val) => this.onChangeAmount(val, 'amount')}
            disabled={this.props.isDisabled}
            inputMode="tel"
            onFocus={(current) => {
              // if value is 0, clear it
              if (current.target.value === '0') {
                this.onChangeAmount('', 'amount');
              }
            }}
            onBlur={(current) => {
              // Clicking off the hours text field if no data was entered, it should default back to 0
              if (current.target.value === '') {
                this.onChangeAmount(1, 'amount');
              }
            }}
          />
        </Col>
        <Col xs={6} sm={3}>
          <Text strong>Unit Cost:</Text>
          <InputNumber
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%' }}
            value={this.props.part.cost}
            onChange={(val) => this.onChangeAmount(val, 'cost')}
            disabled={this.props.isDisabled}
            inputMode="tel"
            onFocus={(current) => {
              // if value is 0, clear it
              if (current.target.value === '$ 0') {
                this.onChangeAmount('', 'cost');
              }
            }}
            onBlur={(current) => {
              // Clicking off the hours text field if no data was entered, it should default back to 0
              if (current.target.value.trim() === '$') {
                this.onChangeAmount(0, 'cost');
              }
            }}
          />
        </Col>
        <Col xs={6} sm={2}>
          <Text strong>Markup:</Text>
          <InputNumber
            value={this.props.part.markup}
            onChange={(val) => this.onChangeAmount(val, 'markup')}
            style={{ width: '100%' }}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace('%', '')}
            disabled={this.props.isDisabled}
            inputMode="tel"
            onFocus={(current) => {
              // if value is 0, clear it
              if (current.target.value === '0%') {
                this.onChangeAmount('', 'markup');
              }
            }}
            onBlur={(current) => {
              // Clicking off the hours text field if no data was entered, it should default back to 0
              if (current.target.value.trim() === '%') {
                this.onChangeAmount(0, 'markup');
              }
            }}
          />
        </Col>
        <Col xs={6} sm={5}>
          <FlexRow>
            <FlexColum style={{ flex: 1 }}>
              <Text strong>Amount:</Text>
              <TotalAmount amount={this.totalAmount} />
            </FlexColum>
            <FlexColum
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                type="link"
                icon="delete"
                className="delete-danger"
                onClick={(event) => {
                  event.stopPropagation();
                  this.onRemoveItem(event, this.props.part.key);
                }}
              />
            </FlexColum>
          </FlexRow>
        </Col>
      </Row>
    );
  }
}

decorate(WorkOrderLineItemPartPanelItem, {
  totalAmount: computed,
  localShopParts: computed,
});

export default observer(WorkOrderLineItemPartPanelItem);
