import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';

import { Col, Icon, Row, Spin, Typography, Card, Button } from 'antd';
import { geekblue, green, red } from '@ant-design/colors';

import { ReactComponent as ExternalIcon } from 'assets/icons/external.svg';
import { ReactComponent as DivrIcon } from 'assets/icons/divr.svg';
import { ReactComponent as DeffectsIcon } from 'assets/icons/deffects.svg';

import { renderWorkOrderAssigned } from 'containers/CarListTable/CarListTableColumns';
import { renderEngineLightStatus } from 'containers/CarListTable/CarListTableColumnsV2';

import VehicleNoteTextarea from 'components/Vehicles/VehicleNoteTextarea';

const { Text, Title } = Typography;

const WrapperCard = styled(Card)`
  .ant-card-actions {
    background: none;
    border: none;
  }

  .ant-card-actions .anticon {
    color: #123fbf;
  }
`;

const WrapperCardMobileContent = styled(Col)`
  clear: both;
  padding: 0 !important;
  margin-bottom: 15px;
`;

const WrapperCardTrailerMobile = styled.div`
  margin-top: 10px;
`;

const CardTitleMobile = styled(Title).attrs({ as: 'h6' })`
  align-items: center;
  display: flex;
  gap: 7px;
  font-size: 14px;
  font-weight: 900;
  line-height: 22px;
  margin-right: 4px;
  margin-bottom: ${({ margin }) => margin || '0'};
  color: ${({ color }) => color || 'inherit'};
`;

const RowTitle = styled(Row).attrs({ as: 'div' })`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
`;

const RowIssue = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IssueStatus = styled.div`
  margin-right: 15px;
  height: 10px;
  width: 10px;
  margin-bottom: ${({ margin }) => margin || '0'};
  border-radius: 100%;
  background: ${({ color }) => color || 'inherit'};
`;

const DivRStatus = styled(Text).attrs({ as: 'span' })`
  font-size: 14px;
  font-weight: 500;
  color: ${({ color }) => color || 'inherit'};
`;

const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
`;

const WrapperCardInfo = styled.div`
  display: flex;
  align-items: flex-end;

  svg {
    margin-bottom: 3px;
  }
`;

const WrapperTitle = styled.div`
  align-items: flex-end;
  display: flex;
  h2,
  svg {
    flex: 1;
  }
`;

const TitleCard = styled(Title).attrs({ as: 'h2' })`
  color: #123fbf;
  font-size: 18px;
  flex: 1;
  font-weight: 500;
  line-height: 1;
  margin-right: 4px;
  margin-bottom: 0;
`;

const WorkOrderButton = styled(Button).attrs({ as: 'button' })`
  align-items: center;
  border: none;
  background: none;
  color: #123fbf;
  display: flex;
  font-size: 14px;
  justify-content: center;
  gap: 12px;
  margin-top: 16px;
  width: 100%;
`;

export const EngineLights = ({ car }) => {
  const engineLightContent = (currentEngineLightStatus) => {
    if (!currentEngineLightStatus) {
      return <Spin />;
    }
    return renderEngineLightStatus({
      engine_light_status: currentEngineLightStatus?.payload,
    });
  };

  return (
    <WrapperCardMobileContent>
      <Row>
        <CardTitleMobile color="#000000A6" margin="7px">
          Engine Lights
        </CardTitleMobile>
        {engineLightContent(car.currentEngineLightStatus)}
      </Row>
    </WrapperCardMobileContent>
  );
};

export const Issues = ({ car }) => {
  const IssuesOptions = [
    {
      color: '#FF0000',
      title: 'Critical',
      option: 'critical',
    },
    {
      color: '#FFB37B',
      title: 'Major',
      option: 'major',
    },
    {
      color: '#EED760',
      title: 'Minor',
      option: 'minor',
    },
  ];

  return (
    <WrapperCardMobileContent>
      <Link
        type="link"
        to={`/${
          car.cartType && String(car.carType) === 'trailer' ? 'trailer' : 'car'
        }/${car.id}`}
      >
        <RowTitle type="flex" align="middle">
          <CardTitleMobile color="#123FBF">Issues</CardTitleMobile>
          <ExternalIcon />
        </RowTitle>
      </Link>
      <>
        {IssuesOptions.map((item) => (
          <RowIssue key={item.option}>
            <Row type="flex" align="middle">
              <IssueStatus color={item.color} />
              <Text>{item.title}</Text>
            </Row>
            {car.issueCounts ? (
              <Text>{car.issueCounts[item.option] || 0}</Text>
            ) : (
              <Text>--</Text>
            )}
          </RowIssue>
        ))}
      </>
    </WrapperCardMobileContent>
  );
};

export const DivR = ({ car, itemId }) => {
  const calculateDefectsAndStatus = (car) => {
    const vehicleDefects = _.get(car, 'dvir.record.payload.vehicleDefects', []);
    const trailerDefects = _.get(car, 'dvir.record.payload.trailerDefects', []);
    const totalDefects = vehicleDefects.length + trailerDefects.length;

    const safetyStatus = _.get(car, 'dvir.record.safetyStatus');
    const options = [
      { color: green.primary, label: 'Safe', key: 'safe' },
      { color: red.primary, label: 'Unsafe', key: 'unsafe' },
      { color: geekblue.primary, label: 'Resolved', key: 'default' },
    ];

    const currentOption =
      options.find((i) => i.key === safetyStatus) ||
      options.find((i) => i.key === 'default');

    return { totalDefects, currentOption };
  };

  const { totalDefects, currentOption } = calculateDefectsAndStatus(car);

  return (
    <WrapperCardMobileContent>
      <Link type="link" to={`/divr/${itemId}`}>
        <RowTitle type="flex" align="middle">
          <CardTitleMobile color="#123FBF">DVIR</CardTitleMobile>
          <ExternalIcon />
        </RowTitle>
      </Link>

      <Row type="flex" align="middle" justify="space-between">
        <IconWrapper>
          <DivrIcon />
          <Text>DIVR</Text>
        </IconWrapper>

        {currentOption && (
          <DivRStatus color={currentOption.color}>
            {currentOption.label}
          </DivRStatus>
        )}
      </Row>
      <Row type="flex" align="middle" justify="space-between">
        <IconWrapper>
          <DeffectsIcon />
          <Text>Defects</Text>
        </IconWrapper>
        <Text>{totalDefects}</Text>
      </Row>
    </WrapperCardMobileContent>
  );
};

export const NextDue = ({ car, element }) => {
  return (
    <WrapperCardMobileContent>
      <Link
        type="link"
        to={`/${
          car.cartType && String(car.carType) === 'trailer' ? 'trailer' : 'car'
        }/${car.id}/?issueStatusFilter=new&source=routine`}
      >
        <RowTitle type="flex" align="middle">
          <CardTitleMobile color="#123FBF">Next Pm</CardTitleMobile>
          <ExternalIcon />
        </RowTitle>
      </Link>
      <>{element}</>
    </WrapperCardMobileContent>
  );
};

export const Notes = ({ car, saveNotes }) => {
  return (
    <WrapperCardMobileContent>
      <CardTitleMobile color="#000000A6">Notes</CardTitleMobile>

      <VehicleNoteTextarea
        car={car}
        onSave={({ id, notes }) => {
          saveNotes({ id, notes });
        }}
      />
    </WrapperCardMobileContent>
  );
};

export const WorkOrder = ({ trailer }) => {
  const [showMore, setShowMore] = useState(false);
  const [workOrderList, setWorkOrderList] = useState([]);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    let itens = trailer.workOrdersAssigned || [];
    if (itens.length >= 5) {
      setShowButton(true);
      setWorkOrderList(itens.slice(0, 5));
    } else {
      setWorkOrderList(itens);
    }
  }, [trailer]);

  useEffect(() => {
    let itens = trailer.workOrdersAssigned || [];
    if (showMore) {
      setWorkOrderList(itens);
    } else {
      setWorkOrderList(itens.slice(0, 5));
    }
  }, [showMore, trailer]);
  return (
    <WrapperCardMobileContent>
      <CardTitleMobile color="#000000A6">Work Order</CardTitleMobile>

      <>{renderWorkOrderAssigned(workOrderList)}</>
      {showButton && (
        <WorkOrderButton onClick={(e) => setShowMore(!showMore)}>
          {showMore ? 'Hide orders' : 'Show More Orders'}
          <Icon type={showMore ? 'up' : 'down'} />
        </WorkOrderButton>
      )}
    </WrapperCardMobileContent>
  );
};

export const CardVehicle = ({
  car,
  isExpanded,
  dropdown,
  toggleCard,
  saveNotes,
  actions,
  nextPm,
}) => {
  return (
    <WrapperCard
      style={{ marginBottom: 24 }}
      actions={[
        <Icon
          fill={geekblue.primary}
          type={isExpanded ? 'up' : 'down'}
          onClick={() => toggleCard(car.id)}
        />,
      ]}
    >
      <Row type="flex" align="middle" justify="space-between">
        <Col span={12}>
          <Link
            type="link"
            to={`/${
              car.cartType && String(car.carType) === 'trailer'
                ? 'trailer'
                : 'car'
            }/${car.id}`}
          >
            <WrapperCardInfo>
              <WrapperTitle>
                <TitleCard>
                  {car.carName || car.my}.{car.id}
                </TitleCard>
                <ExternalIcon />
              </WrapperTitle>
            </WrapperCardInfo>
          </Link>
        </Col>
        <Col span={12}>{dropdown(car)}</Col>
      </Row>

      {isExpanded && (
        <>
          <EngineLights key={`engine_${car.id}`} car={car} />
          <Issues key={`issues_${car.id}`} car={car} />
          <DivR key={`divr_${car.id}`} car={car} itemId={car.id} />
          <NextDue key={`nexdue_${car.id}`} car={car} element={nextPm} />
          <Notes key={`notes_${car.id}`} car={car} saveNotes={saveNotes} />

          {actions}
        </>
      )}
    </WrapperCard>
  );
};

export const CardTrailer = ({ trailer, isExpanded, toggleCard, nextPm }) => {
  return (
    <WrapperCard
      style={{ marginBottom: 24 }}
      actions={[
        <Icon
          fill={geekblue.primary}
          type={isExpanded ? 'up' : 'down'}
          onClick={() => toggleCard(trailer.id)}
        />,
      ]}
    >
      <Row type="flex" align="middle" gutter={[0, 15]}>
        <Link type="link" to={`/trailer/${trailer.id}`}>
          <WrapperCardInfo>
            <TitleCard>
              {trailer.carName || trailer.my}.{trailer.id}
            </TitleCard>
            <ExternalIcon />
          </WrapperCardInfo>
        </Link>
      </Row>

      {isExpanded && (
        <WrapperCardTrailerMobile>
          <NextDue
            key={`nexdue_${trailer.id}`}
            car={trailer}
            element={nextPm}
          />

          <DivR
            key={`divr_${trailer.id}`}
            trailer={trailer}
            itemId={trailer.id}
          />

          {_.isArray(trailer.workOrdersAssigned) && (
            <WorkOrder trailer={trailer} />
          )}
        </WrapperCardTrailerMobile>
      )}
    </WrapperCard>
  );
};
