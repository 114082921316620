import React from 'react';

import PropTypes from 'prop-types';
import { Col, Row, Tag, Typography } from 'antd';
import FormLegend from 'components/Forms/FormLegend';
import FlexRow from 'components/General/FlexRow';
import FlexColum from 'components/General/FlexColum';
import IconWithBorders from 'components/Icons/IconWithBorder';
import _ from 'lodash';
import { getPriorityTagColor } from 'services/Issue';
import IssueObject, {
  getPriorityTagDescription,
} from 'stores/Classes/IssueObject';
import { convertToMonetary } from 'shared/utils';

const { Text } = Typography;

const WorkOrderLineItemsSummary = ({
  lineItems = [],
  shopUsers = [],
  shopParts = [],
  currentCarIssues = [],
}) => {
  return lineItems.map((lineItem) => {
    const hasVmrsInfo = lineItem.vmrs_asm_code && lineItem.vmrs_asm_text;
    let title;
    if (hasVmrsInfo) {
      title = `${lineItem.vmrs_asm_code} - ${lineItem.vmrs_asm_text}`;
    } else {
      title = lineItem.title;
    }
    return (
      <Row key={lineItem.id} gutter={[8, 8]}>
        <Col span={24}>
          <FormLegend>{title}</FormLegend>
        </Col>
        <Col span={24}>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={12}>
              <div>{hasVmrsInfo ? 'VMRs Assembly Code' : 'Service Task'}:</div>
              <Text strong>{title}</Text>
            </Col>
            <Col xs={8} sm={4}>
              <div>Labor Cost:</div>
              <Text strong>{convertToMonetary(lineItem.laborTotal)}</Text>
            </Col>
            <Col xs={8} sm={4}>
              <div>Parts Cost:</div>
              <Text strong>{convertToMonetary(lineItem.partsTotal)}</Text>
            </Col>
            <Col xs={8} sm={4}>
              <div>Subtotal:</div>
              <Text strong>
                {convertToMonetary(lineItem.laborTotal + lineItem.partsTotal)}
              </Text>
            </Col>
            <Col xs={24} sm={24}>
              <div>Description:</div>
              <Text strong>{lineItem.description || 'N/A'}</Text>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {lineItem.labours.map((labour) => {
            let laborName = 'N/A';
            if (_.get(labour, 'labourTechnician.source') === 'pitstop') {
              // labourTechnician.id_technician is the pitstop user ID
              const pitstopUser = _.find(
                shopUsers,
                (user) =>
                  parseInt(user.id, 10) ===
                  parseInt(labour.labourTechnician?.id_technician || -1, 10)
              );
              laborName = pitstopUser?.name || 'N/A';
            } else if (labour.labourTechnician) {
              // labourTechnician.full_name is the local user name
              laborName = labour.labourTechnician.id_technician;
            } else {
              // search the labor in the shopUsers by the labour.id_labour
              const shopUser = _.find(
                shopUsers,
                (user) =>
                  parseInt(user.id, 10) === parseInt(labour.id_labour, 10)
              );
              laborName = shopUser?.name || 'N/A';
            }
            return (
              <Row key={labour.key} style={{ margin: 0 }} gutter={[8, 8]}>
                <Col key={'name'} xs={24} sm={10}>
                  <FlexRow>
                    <FlexColum
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        marginRight: 10,
                      }}
                    >
                      <IconWithBorders icon="user" />
                    </FlexColum>
                    <FlexColum style={{ flex: 1 }}>
                      <div>Labor:</div>
                      <Text strong>{laborName}</Text>
                    </FlexColum>
                  </FlexRow>
                </Col>
                <Col key={'hours'} xs={8} sm={4}>
                  <div>Hours:</div>
                  <Text strong>{labour.hours || 0}</Text>
                </Col>
                <Col key={'cost'} xs={8} sm={4}>
                  <div>Rate:</div>
                  <Text strong>{convertToMonetary(labour.cost || 0)}</Text>
                </Col>
                <Col key={'laborAmount'} xs={8} sm={6}>
                  <div>Amount:</div>
                  <Text strong>
                    {convertToMonetary(labour.hours * labour.cost)}
                  </Text>
                </Col>
              </Row>
            );
          })}
        </Col>
        <Col span={24}>
          {lineItem.parts.map((part) => {
            const cost = parseFloat(part.cost) || 0;
            const markup = parseFloat(part.markup) || 0;
            const amount = parseFloat(part.amount) || 0;

            const partObj = shopParts.find(
              (shopPart) => shopPart.id === part.id_vehicle_part
            );

            const partAmount = (cost + (cost * markup) / 100) * amount;
            return (
              <Row key={part.key} style={{ margin: 0 }} gutter={[8, 8]}>
                <Col key={'name'} xs={24} sm={10}>
                  <FlexRow>
                    <FlexColum
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        marginRight: 10,
                      }}
                    >
                      <IconWithBorders icon="tool" />
                    </FlexColum>
                    <FlexColum style={{ flex: 1 }}>
                      <div>Part:</div>
                      <Text strong>{_.get(partObj, 'name', 'No Name')}</Text>
                    </FlexColum>
                  </FlexRow>
                </Col>
                <Col key={'quantity'} xs={6} sm={4}>
                  <div>Quantity:</div>
                  <Text strong>{part.amount}</Text>
                </Col>
                <Col key={'cost'} xs={6} sm={3}>
                  <div>Cost:</div>
                  <Text strong>{convertToMonetary(part.cost)}</Text>
                </Col>
                <Col key={'markup'} xs={6} sm={3}>
                  <div>Markup:</div>
                  <Text strong>{convertToMonetary(part.markup, false)}</Text>
                </Col>
                <Col key={'partAmount'} xs={6} sm={4}>
                  <div>Amount:</div>
                  <Text strong>{convertToMonetary(partAmount)}</Text>
                </Col>
              </Row>
            );
          })}
        </Col>
        <Col span={24}>
          {lineItem.services.map((service) => {
            const issue = currentCarIssues.find(
              (issue) => issue.id === service.id_vehicle_service
            );
            if (!issue) {
              return null;
            }
            const issueObj = new IssueObject(issue);
            return (
              <Row
                key={service.id_vehicle_service}
                style={{ margin: 0 }}
                gutter={[8, 8]}
              >
                <Col key={'name'} span={24}>
                  <FlexRow>
                    <FlexColum
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        marginRight: 10,
                      }}
                    >
                      <IconWithBorders icon="warning" />
                    </FlexColum>
                    <FlexColum style={{ flex: 1 }}>
                      <div>Issue:</div>
                      <div>
                        <Tag color={getPriorityTagColor(issueObj.priority)}>
                          {getPriorityTagDescription(issueObj.priority)}
                        </Tag>
                        <Text strong>{issueObj.getName()}</Text>
                      </div>
                    </FlexColum>
                  </FlexRow>
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    );
  });
};

WorkOrderLineItemsSummary.propTypes = {
  workOrder: PropTypes.object,
  shopUsers: PropTypes.array,
  shopParts: PropTypes.array,
  currentCarIssues: PropTypes.array,
};

export default WorkOrderLineItemsSummary;
