import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { decorate, observable, computed } from 'mobx';
import PropTypes from 'prop-types';

import { Col, Row, Typography, Select, Tag, Button } from 'antd';

import IssueObject, {
  getPriorityTagColor,
  getPriorityTagDescription,
} from 'stores/Classes/IssueObject';

import FlexRow from 'components/General/FlexRow';
import FlexColum from 'components/General/FlexColum';
import IconWithBorders from 'components/Icons/IconWithBorder';
import WorkOrderLineItemContext from '../LineItems/WorkOrderLineItemContext';

const { Text } = Typography;
const { Option } = Select;

class WorkOrderLineItemIssuesItem extends Component {
  static contextType = WorkOrderLineItemContext;

  static propTypes = {
    childRef: PropTypes.func,
    item: PropTypes.object,
    onRemoveItem: PropTypes.func,
    currentCarIssues: PropTypes.array,
    onUpdateLocalItem: PropTypes.func.isRequired,
    displayIcon: PropTypes.bool,
  };

  localItem = {
    id_vehicle_service: '',
  };

  get issuesOptions() {
    const formatLabel = (issue) => {
      const issueObject = new IssueObject(issue);
      return (
        <>
          <Tag color={getPriorityTagColor(issue.priority)}>
            {getPriorityTagDescription(issue.priority)}
          </Tag>
          <Text>{issueObject.getName()}</Text>
        </>
      );
    };
    return (this.props?.currentCarIssues || []).map((issue, index) => (
      <Option key={index} value={issue.id}>
        {formatLabel(issue)}
      </Option>
    ));
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.localItem = this.props.item;
  }

  onChangeSelectField = (value, fieldName) => {
    this.localItem[fieldName] = value;

    if (this.props.onUpdateLocalItem) {
      this.props.onUpdateLocalItem(this.localItem);
    }
  };

  onRemoveItem = (event, itemKey) => {
    if (this.props.onRemoveItem) {
      this.props.onRemoveItem(event, itemKey);
    }
  };

  render() {
    const { screenSize } = this.context;

    return (
      <Row style={{ margin: 0 }} gutter={[8, 8]}>
        <Col key={'name'} span={24}>
          <FlexRow>
            {this.props.displayIcon ? (
              <FlexColum
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginRight: 10,
                }}
              >
                <IconWithBorders icon="warning" />
              </FlexColum>
            ) : (
              <FlexColum
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginRight: screenSize <= 575 ? 0 : 10,
                }}
              >
                <div
                  style={{
                    width: 35,
                  }}
                />
              </FlexColum>
            )}
            <FlexColum style={{ flex: 1 }}>
              <Text strong>Issue</Text>
              <Select
                value={this.localItem.id_vehicle_service}
                onChange={(value) =>
                  this.onChangeSelectField(value, 'id_vehicle_service')
                }
                placeholder="Select a Issue"
              >
                {this.issuesOptions}
              </Select>
            </FlexColum>
            <FlexColum
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                type="link"
                icon="delete"
                className="delete-danger"
                onClick={(event) => {
                  event.stopPropagation();
                  this.onRemoveItem(event, this.props.item.key);
                }}
              />
            </FlexColum>
          </FlexRow>
        </Col>
      </Row>
    );
  }
}

decorate(WorkOrderLineItemIssuesItem, {
  localItem: observable,
  issuesOptions: computed,
});

export default observer(WorkOrderLineItemIssuesItem);
