import React from 'react';
import { Card, Spin, Typography } from 'antd';
import FlexRow from 'components/General/FlexRow';
import './PartStockWidget.less';

const { Text } = Typography;

interface StockInfo {
  loading?: boolean;
  inStock: number;
  lowStock: number;
  outOfStock: number;
  onClickLabel: (label: string) => void;
}

const PartStockWidget: React.FC<StockInfo> = ({
  loading = false,
  inStock,
  lowStock,
  outOfStock,
  onClickLabel,
}) => {
  //Extra: <Text style={{ cursor: 'pointer' }}>All Assets</Text>
  return (
    // @ts-ignore
    <Spin spinning={loading} tip="Loading...">
      <Card
        className="part-stock-widget"
        title={
          <Text strong style={{ color: '#273DB1', fontSize: '16px' }}>
            Current Stock{' '}
            <span className="filter-helper">
              (Click on any label to filter)
            </span>
          </Text>
        }
        style={{ width: '100%' }}
      >
        <FlexRow style={{ marginBottom: 5 }}>
          <Text className="label" onClick={() => onClickLabel('In Stock')}>
            In Stock
          </Text>
          <Text className="counter" strong style={{ float: 'right' }}>
            {inStock.toLocaleString()}
          </Text>
        </FlexRow>
        <FlexRow style={{ marginBottom: 5 }}>
          <Text className="label" onClick={() => onClickLabel('Low Stock')}>
            Low Stock
          </Text>
          <Text className="counter" strong style={{ float: 'right' }}>
            {lowStock.toLocaleString()}
          </Text>
        </FlexRow>
        <FlexRow style={{ marginBottom: 5 }}>
          <Text className="label" onClick={() => onClickLabel('Out of Stock')}>
            Out of Stock
          </Text>
          <Text className="counter" strong style={{ float: 'right' }}>
            {outOfStock.toLocaleString()}
          </Text>
        </FlexRow>
      </Card>
    </Spin>
  );
};

export default PartStockWidget;
