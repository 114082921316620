import React, { useState, useEffect, useCallback } from 'react';
import { Table, Spin, Col, Button, Checkbox, Row, Input, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { Icon } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { IssueStore, CurrentUserStore } from 'stores';
import MarkAsCompleteBtn from 'containers/IssueProfile/MarkAsCompleteBtn'; 
import CreateWorkOrderFromIssuesBtn from 'containers/IssueProfile/CreateWorkOrderFromIssuesBtn'; 
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd'; 
// Styled components
const NextPMPercentageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .percentage {
    font-size: 20px;
  }
  .description {
    font-size: 12px;
  }
  .ant-tag {
    border-radius: 100px;
    background-color: white;
    margin-top: 5px;
    font-size: 12px;
  }
`;

const FilterDropdownWrapper = styled.div`
  padding: 8px;
  .filter-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }
  .checkbox-group {
    display: flex;
    flex-direction: column;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;

  .button-wrapper {
    margin-right: 10px; /* Add space between the buttons */
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .ant-input {
    margin-right: 20px;
  }

  .search-icon {
    font-size: 16px;
  }
`;

const NextPMFilterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, setFilters }) => {
  const handleFilter = () => {
    confirm();
    setFilters((prev) => ({
      ...prev,
      nextPM: selectedKeys,
      pm_status: selectedKeys.length > 0 ? selectedKeys : undefined,
    }));
  };

  const handleReset = () => {
    clearFilters();
    setFilters((prev) => ({
      ...prev,
      nextPM: [],
      pm_status: undefined,
    }));
  };

  return (
    <FilterDropdownWrapper>
      <Checkbox.Group
        className="checkbox-group"
        options={[
          { label: 'Overdue', value: 'Overdue' },
          { label: 'Due Soon', value: 'Due soon' },
          { label: 'No action needed', value: 'No action needed' },
        ]}
        value={selectedKeys}
        onChange={(checkedValues) => setSelectedKeys(checkedValues)}
      />
      <div style={{ borderTop: '1px solid #d9d9d9', marginBottom: '8px', marginTop: '8px' }}></div>  
      <div className="filter-buttons">
        <Button type="primary" onClick={handleReset} size="small">
          Reset
        </Button>
        <Button  onClick={handleFilter} size="small">
          OK
        </Button>
      </div>
    </FilterDropdownWrapper>
  );
};

const NextPMWrapper = ({
  percent,
  pm_description,
  pm_status,
  due_time_in_s,
  rule_type,
  due_engine_hours,
  engineHours,
  due_mileage,
  totalMileage,
  safetyInspection
}) => {
  let displayValue;
  let displayText;
  if (rule_type === 'time') {
    const now = new Date();
    const dueAt = moment.unix(due_time_in_s);
    const diffInDays = dueAt.diff(now, 'days');
    const diffInMonths = Math.ceil(diffInDays / 30.4375);

    if (diffInMonths < 1) {
      displayValue = Math.abs(diffInDays);
      displayText = 'day(s)';
    } else {
      displayValue = Math.abs(diffInMonths);
      displayText = 'month(s)';
    }

    if(safetyInspection) {
      const dueInDays = moment.unix(due_time_in_s).diff(moment(), 'days');
      const overdueDays = moment().diff(moment.unix(due_time_in_s), 'days');

      const overdueOrDueIn = due_time_in_s
        ? overdueDays > 0
          ? overdueDays
          : dueInDays
        : null;

      displayValue = overdueOrDueIn;
      displayText = 'days';
    }

  } else {
    const hoursRemainingValue =
      rule_type === 'engine_hours'
        ? Math.ceil(
            (isNaN(Number(due_engine_hours)) || isNaN(Number(engineHours))
              ? 0
              : Math.abs(Number(due_engine_hours) - Number(engineHours)) / 3600)
          )
        : null;

    const kmRemaining =
      rule_type === 'mileage'
        ? Math.ceil(
            (isNaN(Number(due_mileage)) || isNaN(Number(totalMileage))
              ? 0
              : Math.abs(Number(due_mileage) - Number(totalMileage)))
          )
        : null;

    const milesRemaining = kmRemaining
      ? Math.ceil(kmRemaining / 1.60934)
      : null;

    const userSettings = CurrentUserStore.user.settings;
    const milesOrKm =
      userSettings?.odometer === 'km' ? kmRemaining : milesRemaining;
    const milesOrKmText = userSettings?.odometer === 'km' ? 'km' : 'miles';

    displayText = milesOrKm !== null ? milesOrKmText : 'engine hours';
    const milesOrEngineHours =
      milesOrKm !== null ? milesOrKm : hoursRemainingValue;

    displayValue = milesOrEngineHours !== null ? Math.abs(milesOrEngineHours) : '--';
  }

  const getColor = () => {
    switch (pm_status) {
      case 'Overdue':
        return '#FF202F';
      case 'Due soon':
        return '#FF812A';
      case 'No action needed':
        return safetyInspection ? '#52c41a' : '#000000';
      default:
        return '#333333';
    }
  };

  return (
    <NextPMPercentageWrapper>
      <span className="percentage" style={{ color: getColor() }}>
        {Math.max(0, Math.floor(percent))}%
      </span>
      <span className="description" style={{ color: getColor() }}>
        { safetyInspection ? `In Compliance` : pm_status}
      </span>
      <span className="description">
        {pm_status === 'Overdue' ? 'Overdue by' : 'In'} {displayValue}{' '}
        {displayText}
      </span>
    </NextPMPercentageWrapper>
  );
};

const PMTable = ({ carId, showEdit = false, onIssuesListChange , isSafetyInspection = false }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [safetyInspection] = useState(isSafetyInspection);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const history = useHistory();
  
  const fetchData = useCallback(async (page, pageSize, appliedFilters, appliedSort) => {
    try {
      setLoading(true);

      const sortParam = appliedSort.length > 0 ? appliedSort.map(s => `${s.ascending ? '' : '-'}${s.key}`) : '';
      const result = await IssueStore.getPmsForNewTable(
        {
          offset: (page - 1) * pageSize,
          limit: pageSize,
          filter: { status: 'new', source: 'routine', pm_status: appliedFilters.pm_status, ...appliedFilters },
          sort: sortParam,
          search: searchValue,
          safetyInspection: safetyInspection
        },
        carId,
        false,
        false,
        );

      if (result && result.data) {
        const mappedData = result.data.map((item, index) => ({
          key: item.id || index.toString(),
          ...item,
          serviceName: item.name,
          description: item.description || '--',
          serviceIntervals: {
            interval_month: item.routineInfo?.interval_month || null,
            interval_mileage: item.routineInfo?.interval_mileage || null,
            interval_engine_hours: item.routineInfo?.interval_engine_hours || null,
          },
          nextPMDue: {
            ...item,
            percent: item.active_pm_percent,
            pm_status: item.active_pm_status,
            dueMessages: [{ message: `In ${item.dueMileage || '--'} miles` }],
          },
          lastPMDone: item.last_pm_done_date,
        }));

        setData(mappedData);
        setTotal(result.meta?.total || 0);
      }
    } catch (error) {
      console.error('Error fetching PM data:', error);
    } finally {
      setLoading(false);
    }
  }, [carId, searchValue, safetyInspection]);

  useEffect(() => {
    if (carId) {
      fetchData(currentPage, pageSize, filters, sort);
    }
  }, [carId, currentPage, pageSize, filters, sort, fetchData]);

  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);

    if (sorter.field) {
      const newSort = [
        {
          key: sorter.field,
          ascending: sorter.order === 'ascend'
        }
      ];
      setSort(newSort);
    } else {
      setSort([]);
    }
  };

  // Refresh vehicle list function
  const refreshVehiclesList = () => {
    if (onIssuesListChange) {
      onIssuesListChange();
    }
  };

  const refreshData = () => {
    fetchData(currentPage, pageSize, filters, sort);
  };

  const openCreatedWorkOrderDetails = (workOrder) => {
    history.push(`/work-order-desktop/${workOrder.id}/edit`);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleRemoveServices = async () => {
    try {
      await IssueStore.removeSelectedServices(selectedRows);
      setSelectedRows([]);
      refreshData();
      refreshVehiclesList();
      notification.success({
        message: 'Services removed successfully',
      });
    } catch (error) {
      notification.error({
        message: 'Error removing services',
        description: error.message,
      });
    }
    setPopoverVisible(false); // Hide popover after removing
  };

  const handleCancel = () => {
    setPopoverVisible(false); // Hide popover when "No" is clicked
  };

  const removeServicesContent = (
    <div style={{ padding: '10px', maxWidth: '500px' }}>
      {/* Row with middle alignment for vertical centering */}
      <Row style={{ display: 'flex', alignItems: 'center' }}> {/* Flex container for horizontal alignment */}
        <ExclamationCircleOutlined style={{ color: '#faad14', fontSize: '20px', marginRight: '8px' }} /> {/* Warning icon */}
        <p style={{ margin: 0 }}>Are you sure you want to remove the selected services? This action cannot be undone.</p>
      </Row>
      <div style={{ textAlign: 'right', marginTop: '10px' }}>
        <Button onClick={handleCancel} style={{ marginRight: '10px' }}>
          No
        </Button>
        <Button type="primary" onClick={handleRemoveServices}>
          Yes
        </Button>
      </div>
    </div>
  );


  const columns = [
    {
      title: safetyInspection ? 'Safety Service Name' : 'Service Name',
      dataIndex: 'serviceName',
      key: 'serviceName',
      sorter: true,
    },
    {
      title: safetyInspection ? 'Safety Description' : 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => (text ? text : '--'),
      sorter: true,
    },
    {
      title: safetyInspection ? 'Safety Service Intervals' : 'Service Intervals',
      dataIndex: 'serviceIntervals',
      key: 'serviceIntervals',
      render: (intervals) => {
        const { interval_month, interval_mileage, interval_engine_hours } = intervals;
        
        return (
          <>
            {interval_month && <div>Time: Every {interval_month} Months</div>}
            {interval_mileage && <div>Mileage: Every {interval_mileage.toLocaleString()} Miles</div>}
            {interval_engine_hours && <div>Engine Hours: Every {interval_engine_hours} Hours</div>}
          </>
        );
      },
      sorter: true,
    },
    {
      title: safetyInspection ? 'Safety Next PM Due' : 'Next PM Due',
      dataIndex: 'nextPMDue',
      key: 'active_pm_status',
      render: (nextPMDue) => (
        <NextPMWrapper
          percent={nextPMDue.percent}
          pm_description={nextPMDue.pm_description || "Routine Service"}
          pm_status={nextPMDue.pm_status}
          due_time_in_s={nextPMDue.dueTime}
          rule_type={nextPMDue.rule_type}
          due_engine_hours={nextPMDue.dueEngineHours}
          engineHours={nextPMDue.engineHours}
          due_mileage={nextPMDue.due_mileage}
          totalMileage={nextPMDue.totalMileage}
          safetyInspection={safetyInspection}
        />
      ),
      align: 'center',
      filterDropdown: (props) => <NextPMFilterDropdown {...props} setFilters={setFilters} />,
      sorter: true,
    },
    {
      title: safetyInspection ? 'Safety Last PM Done' : 'Last PM Done',
      dataIndex: 'lastPMDone',
      key: 'lastPMDone',
      render: (date) => (date ? moment(date).format('MMM DD, YYYY') : '--'),
      sorter: true,
    },
  ];
  

  if (showEdit) {
    columns.push({
      title: '',
      key: 'edit',
      width: 150,
      render: (_, record) => (
        <Col span={24}>
          <Link to={`/maintenance`}>
            <Icon type="edit" />
            <span className="marginLeft5">Edit</span>
          </Link>
        </Col>
      ),
    });
  }

  const rowSelection = {
    selectedRowKeys, // Bind selected row keys
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedRowKeys(selectedRowKeys); // Update selected row keys
    },
    getCheckboxProps: (record) => ({
      disabled: record.serviceName === 'Disabled',
    }),
  };
  

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={24}>
          <SearchWrapper>
            <Input
              placeholder="Search Service Name"
              value={searchValue}
              onChange={handleSearchChange}
              suffix={<Icon type="search" className="search-icon" />}
              style={{ width: '33.33%' }}
            />
          </SearchWrapper>
        </Col>
        <Col span={24}>
          <ButtonContainer>
            <div className="button-wrapper" style={{ marginRight: '10px' }}>
            <Popover
                content={removeServicesContent}
                trigger="click"
                visible={popoverVisible}
                placement="bottomLeft" // Place the popover aligned to the bottom-left
                overlayInnerStyle={{ padding: '16px', borderRadius: '8px' }} // Optional: Add more styling if needed
                overlayStyle={{
                  left: '10px', // This will shift the entire popover's placement
                  // This directly styles the arrow:
                  '.ant-popover-arrow': {
                    left: '10px !important', // Move the triangle to the left
                  },
                }}
                onVisibleChange={(visible) => setPopoverVisible(visible)} // Handle visibility changes
              >
                <Button
                  style={{
                    backgroundColor: selectedRows.length > 0 ? '#FF0000' : '',  // Red color if rows are selected
                    color: selectedRows.length > 0 ? '#fff' : '',               // White text if rows are selected
                    borderColor: selectedRows.length > 0 ? '#FF0000' : '',      // Red border if rows are selected
                  }}
                  disabled={selectedRows.length === 0}
                >
                  Remove Services ({selectedRows.length})
                </Button>
              </Popover>
            </div>
            <div className="button-wrapper" style={{ marginRight: '10px' }}>
              <MarkAsCompleteBtn
                issues={selectedRows}
                onClick={() => {
                  setSelectedRows([]);
                  refreshData();
                  refreshVehiclesList();
                }}
                title={`Mark as Complete (${selectedRows.length})`}
                type="primary"
                shouldDisableButton={selectedRows.length === 0}
              />
            </div>
            <div className="button-wrapper">
            <CreateWorkOrderFromIssuesBtn
              issues={selectedRows}
              onFinish={(workOrderCreated) => {
                notification.success({
                  message: 'Work orders saved',
                  description: (
                    <div>
                      The Work Order was created{' '}
                      <Button
                        style={{ padding: 0 }}
                        type="link"
                        onClick={() => openCreatedWorkOrderDetails(workOrderCreated)}
                      >
                        Click Here
                      </Button>{' '}
                      to view the details and to make edits.
                    </div>
                  ),
                  key: 'orderUpdated',
                  onClose: () => setSelectedRows([]),
                });
                setSelectedRows([]); 
                setSelectedRowKeys([]);
                refreshData();
              }}
              title={`Create Work Order (${selectedRows.length})`}
              shouldDisableButton={selectedRows.length === 0}
            />
            </div>
          </ButtonContainer>
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: total,
              showSizeChanger: false,
            }}
            onChange={handleTableChange}
            rowSelection={rowSelection}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default PMTable;