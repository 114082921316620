import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { observe } from 'mobx';

import styled from 'styled-components';
import { PitstopButton } from 'shared';
import PropTypes from 'prop-types';
import PitstopSimpleForm from 'components/PitstopSimpleForm';
import { AppStore, WorkOrderStore, ShopStore } from 'stores';
import DealershipChooser from 'components/DealershipChooser';

import { webServiceProvider } from 'shared';

import {
  Form,
  Icon,
  Select,
  Row,
  Col,
  Input,
  Tooltip,
  Spin,
  Upload,
  Modal,
  Typography,
  Button,
  InputNumber,
  DatePicker,
  AutoComplete,
} from 'antd';
import { Logger } from 'stores/Classes';

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 25px;
`;

const Wrapper = styled.div`
  position: relative;

  .hide-label {
    label {
      visibility: hidden;
    }
  }
`;

const NoImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  border-radius: 5px;

  height: 200px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 215px;
  height: 215px;
  overflow: hidden;
  background-color: #f0f0f0;
`;

const UploaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
  height: auto;

  .ant-upload-picture-card-wrapper {
    width: auto;
    margin-bottom: 20px;
  }

  .item {
    margin-right: 20px;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;

    width: 100px;
    height: 100px;
    background-color: #d5d3d3;

    &.has-error {
      border: solid 2px red;
      border-style: dotted;
    }

    .remove-button {
      position: absolute;
      top: -11px;
      right: -10px;
      opacity: 1;
      background-color: #333333;
      color: white;

      &:hover {
        background-color: black;
        color: white;
        border-color: black;
      }
    }
  }

  .upload-list-inline .ant-upload-list-item .ant-upload-list-item-actions {
    top: 0;
    right: 60px;
    opacity: 1;

    a {
      color: red;
    }

    i {
      color: red;
    }
  }
`;

const ImageUploadWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  align-items: center;
  justify-content: center;
`;

// 10000000 = 10MB
// 1000000 = 1MB
const MAX_FILE_SIZE = 10000000;

class PartDetailsPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
  };

  state = {
    loading: true,
    purchaseHistoryData: [],
    shopParts: [],
    currentPart: {},
    previewVisible: false,
    previewImage: '',
    fileList: [],
    partImage: null,
    assetMakeDataSource: [],
    assetTypeDataSource: ['Vehicle', 'Trailer', 'Misc.'],
  };

  purchaseHistoryColumns = [
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      key: 'vendor',
      render: (vendor) => (
        <Select
          placeholder="Please select"
          value={vendor}
          style={{ width: 120 }}
        >
          <Option value="jack">
            <Fragment>
              <div style={{ fontWeight: 'bold' }}>Vendor #1</div>
              <span style={{ color: 'grey' }}>
                4512 3rd Ave Save Mart
                <br />
                Seattle, WA 98101
              </span>
            </Fragment>
          </Option>
          <Option value="lucy">
            <Fragment>
              <div style={{ fontWeight: 'bold' }}>Vendor #2</div>
              <span style={{ color: 'grey' }}>
                4512 3rd Ave Save Mart
                <br />
                Seattle, WA 98101
              </span>
            </Fragment>
          </Option>
          <Option value="jane">
            <Fragment>
              <div style={{ fontWeight: 'bold' }}>Vendor #2</div>
              <span style={{ color: 'grey' }}>
                4512 3rd Ave Save Mart
                <br />
                Seattle, WA 98101
              </span>
            </Fragment>
          </Option>
        </Select>
      ),
    },
    {
      title: 'PO Amount',
      dataIndex: 'poAmount',
      key: 'poAmount',
      render: (poAmount) => (
        <Input
          type="number"
          prefix={<Icon type="dollar" style={{ color: 'rgba(0,0,0,.25)' }} />}
        />
      ),
    },
    {
      title: 'Number of Units',
      dataIndex: 'numberOfUnits',
      key: 'numberOfUnits',
      render: () => <Input type="number" />,
    },
    {
      title: 'Date Ordered',
      dataIndex: 'dateOrdered',
      key: 'dateOrdered',
      render: (dateOrdered) => (
        <Input
          prefix={<Icon type="calendar" style={{ color: 'rgba(0,0,0,.25)' }} />}
        />
      ),
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Fragment>
          <Tooltip title="Delete the Vendor">
            <PitstopButton
              icon="delete"
              type="danger"
              onClick={() => this.onClickDeleteVendor(record)}
            />
          </Tooltip>
        </Fragment>
      ),
    },
  ];

  componentDidMount() {
    this.getCurrentPartData();
  }

  async getCurrentPartData() {
    await this.loadSelectedShopParts(ShopStore.currentShop.id);
    const currentPart =
      this.state.shopParts.find(
        (part) => part.id === +this.props.match.params.id
      ) || {};
    this.setState({
      currentPart,
      fileList: currentPart.images
        ? currentPart.images.split(',').map((image, index) => ({
            uid: index,
            name: 'image.png',
            status: 'done',
            // status: 'error',
            // response: 'Server Error 500', // custom error message to show
            url: image,
          }))
        : [],
      partImage: currentPart.images ? currentPart.images.split(',')[0] : null,
    });
  }

  loadSelectedShopParts = async (shopId) => {
    try {
      this.setState({ loading: true });
      const shopParts = await WorkOrderStore.getShopParts(shopId);
      this.setState({ shopParts });
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  disposer = observe(ShopStore.currentShop, 'id', async () => {
    if (ShopStore.isAdmin()) return;
    return this.getCurrentPartData();
  });

  componentWillUnmount() {
    this.disposer();
  }

  navigateToList = () => {
    this.props.history.push('/parts');
  };

  updateImages = async (partId) => {
    const { fileList } = this.state;
    AppStore.addLoading('Updating images...');
    // get the difference between the current images and the new images
    const currentImages = this.state.currentPart.images
      ? this.state.currentPart.images.split(',')
      : [];
    const newImages = fileList.map((file) => file.originFileObj || file.url);
    const imagesToDelete = currentImages.filter(
      (image) => !newImages.includes(image)
    );

    // delete the images that are no longer in the list
    if (imagesToDelete.length > 0) {
      await Promise.all(
        imagesToDelete.map(async (image) => {
          try {
            await webServiceProvider.delete(
              `dashboard/v1/deleteimage?imageUrl=${image}`
            );
          } catch (e) {
            AppStore.addError(`Image ${image} deleted failed!`);
          }
        })
      );
    }

    // upload the new images
    const nonDuplicatedImages = newImages.filter(
      (image) => !currentImages.includes(image)
    );
    const duplicatedImages = newImages.filter((image) =>
      currentImages.includes(image)
    );
    const partImages = [...duplicatedImages];
    if (nonDuplicatedImages.length > 0) {
      for (const image of nonDuplicatedImages) {
        const fd = new FormData();
        if (image instanceof File) {
          fd.append('singleInputFileName', image);
        } else {
          // create a File object from the url
          const response = await fetch(image);
          const blob = await response.blob();
          const file = new File([blob], 'image.png', { type: 'image/png' });
          fd.append('singleInputFileName', file);
        }
        try {
          const data = await webServiceProvider.postFormData(
            'dashboard/v1/uploadimage',
            fd
          );
          if (data.fileUrl) {
            partImages.push(data.fileUrl);
          }
        } catch (e) {
          AppStore.addError(
            `Image ${this.state.fileList[0].name} uploaded failed!`
          );
        }
      }
    }
    AppStore.removeLoading('Updating images...');
    AppStore.addLoading('Updating part...');
    try {
      const formattedImages = partImages.join(',');
      await WorkOrderStore.updatePartOnCustomEndpoint({
        id: partId,
        images: formattedImages || null,
      });
    } catch (error) {
      AppStore.addError('Failed to update part with images.');
    } finally {
      AppStore.removeLoading('Updating part...');
    }
    return Promise.resolve();
  };

  onSubmit = () => {
    // get form values
    const { form } = this.props;
    form.validateFields(async (err, values) => {
      if (err) return;
      // check the images to see if they are too big
      const tooBigImages = this.state.fileList.filter(
        (file) => file.size && file.size > MAX_FILE_SIZE
      );
      if (tooBigImages.length > 0) {
        AppStore.addError('Image too big. Must be less than 10MB.');
        return;
      }
      // save the part
      try {
        const partId = this.props.match.params.id;
        await WorkOrderStore.updatePartOnCustomEndpoint({
          id: partId,
          ...values,
        });
        // update the name too
        AppStore.addSuccess('The part was saved!');
        await this.updateImages(partId);
        this.props.history.push('/parts');
      } catch (error) {
        Logger.error(error);
      }
    });
  };

  onDelete = async () => {
    try {
      const partId = this.props.match.params.id;
      await WorkOrderStore.deletePartOnCustomEndpoint(partId);
      AppStore.addSuccess('The part was deleted!');
      this.props.history.push('/parts');
    } catch (error) {
      Logger.error(error);
    }
  };

  onAddVendor = () => {
    const { purchaseHistoryData } = this.state;
    const newData = {
      key: purchaseHistoryData.length + 1,
      vendor: null,
      poAmount: '',
      numberOfUnits: 1,
      dateOrdered: '',
    };
    this.setState({
      purchaseHistoryData: [...purchaseHistoryData, newData],
    });
  };

  onClickDeleteVendor = (record) => {
    const { purchaseHistoryData } = this.state;
    const newData = purchaseHistoryData.filter(
      (item) => item.key !== record.key
    );
    this.setState({
      purchaseHistoryData: newData,
    });
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      // if file not blob type, resolve with file
      if (!(file instanceof Blob)) {
        return resolve(file);
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = async ({ fileList }) => {
    const newStateToSet = { fileList };
    if (fileList.length > 0) {
      // check each image to see if the size is too big
      const tooBigImages = fileList.filter((file) => file.size > MAX_FILE_SIZE);
      // change the image to an error state
      // tooBigImages.forEach((file) => {
      //   file.status = 'error';
      //   file.response = 'Image too big. Must be less than 10MB.';
      // });
      if (tooBigImages.length) {
        // remove the image from the list
        newStateToSet.fileList = fileList.filter(
          (file) => file.size <= MAX_FILE_SIZE
        );
        this.setState({
          uploadErrorMessage: 'Image too big. Must be less than 10MB.',
        });
      } else {
        this.setState({
          uploadErrorMessage: '',
        });
        const base64Image = await this.getBase64(
          fileList[0].originFileObj || fileList[0].url
        );
        newStateToSet.partImage = base64Image;
      }
    } else {
      newStateToSet.partImage = null;
    }
    this.setState(newStateToSet);
  };

  handleRemove = (event, fileUid) => {
    event.stopPropagation();
    const { fileList } = this.state;
    const newFileList = fileList.filter((file) => file.uid !== fileUid);
    this.setState({ fileList: newFileList });

    this.handleChange({
      fileList: newFileList,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Fragment>
        <Wrapper>
          <div style={{ position: 'absolute', right: '0px' }}>
            <PitstopButton
              type="default"
              icon="left"
              onClick={this.navigateToList}
            >
              Back to Parts
            </PitstopButton>
          </div>
          <h1>Part Details</h1>

          <DealershipChooser />

          {/* If loading state is true, show a loading indicator */}
          {this.state.loading && (
            <LoadingContainer>
              <Spin tip="Loading..." />
            </LoadingContainer>
          )}

          <Row gutter={8}>
            <Col offset={3} span={14}>
              <PitstopSimpleForm
                submitLabel="Save Part"
                deleteLabel="Delete Part"
                onSubmit={this.onSubmit}
                onDelete={this.onDelete}
              >
                <Row gutter={16}>
                  <Col span={16}>
                    <Form.Item
                      label="Part Number"
                      hasFeedback
                      help="Internal part identifier. Must be unique per part."
                    >
                      {getFieldDecorator('number', {
                        initialValue: this.state.currentPart.number,
                        rules: [
                          {
                            required: true,
                            message: 'Please input the Part Number!',
                          },
                        ],
                      })(<Input />)}
                    </Form.Item>
                    <Form.Item label="Part Description" hasFeedback>
                      {getFieldDecorator('description', {
                        initialValue: this.state.currentPart.description,
                        rules: [
                          {
                            required: true,
                            message: 'Description is required.',
                          },
                          {
                            max: 255,
                            message:
                              'The description needs to be less than 255 characters.',
                          },
                        ],
                      })(<TextArea rows={2} />)}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    {this.state.partImage ? (
                      <ImageWrapper>
                        <img
                          src={this.state.partImage}
                          alt="part"
                          style={{
                            width: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      </ImageWrapper>
                    ) : (
                      <NoImageWrapper>
                        <Icon
                          type="inbox"
                          style={{ fontSize: '50px', color: 'lightgrey' }}
                        />
                        <div style={{ color: 'lightgrey' }}>
                          No images added
                        </div>
                      </NoImageWrapper>
                    )}
                  </Col>
                </Row>

                {/* Asset type and make */}
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item label="Asset Type" hasFeedback>
                      {getFieldDecorator('type', {
                        initialValue: this.state.currentPart.type,
                        rules: [],
                      })(
                        <AutoComplete
                          dataSource={this.state.assetTypeDataSource}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Asset Make" hasFeedback>
                      {getFieldDecorator('make', {
                        initialValue: this.state.currentPart.make,
                        rules: [],
                      })(
                        <AutoComplete
                          dataSource={this.state.assetMakeDataSource}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col span={8}>
                    <Form.Item label="Current Stock" hasFeedback>
                      {getFieldDecorator('currentStock', {
                        initialValue: this.state.currentPart.currentStock,
                        rules: [
                          {
                            required: true,
                            message: 'Current stock is required.',
                          },
                          // don't allow negative numbers
                          {
                            validator: (rule, value, callback) => {
                              if (value < 0) {
                                callback('Stock cannot be negative.');
                              }
                              callback();
                            },
                          },
                          // don't allow decimals
                          {
                            validator: (rule, value, callback) => {
                              if (value % 1 !== 0) {
                                callback('Stock cannot be a decimal.');
                              }
                              callback();
                            },
                          },
                        ],
                      })(
                        <InputNumber
                          style={{ width: '100%' }}
                          prefix={
                            <Icon
                              type="block"
                              style={{ color: 'rgba(0,0,0,.25)' }}
                            />
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Stock Limit" hasFeedback>
                      {getFieldDecorator('stocklimit', {
                        initialValue: this.state.currentPart.stocklimit,
                        rules: [
                          {
                            required: true,
                            message: 'Stock limit is required.',
                          },
                          // don't allow negative numbers
                          {
                            validator: (rule, value, callback) => {
                              if (value < 0) {
                                callback('Stock limit cannot be negative.');
                              }
                              callback();
                            },
                          },
                          // don't allow decimals
                          {
                            validator: (rule, value, callback) => {
                              if (value % 1 !== 0) {
                                callback('Stock limit cannot be a decimal.');
                              }
                              callback();
                            },
                          },
                        ],
                      })(
                        <InputNumber
                          style={{ width: '100%' }}
                          prefix={
                            <Icon
                              type="block"
                              style={{ color: 'rgba(0,0,0,.25)' }}
                            />
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Unit Cost" hasFeedback>
                      {getFieldDecorator('cost', {
                        initialValue: this.state.currentPart.cost,
                        rules: [],
                      })(
                        <Input
                          type="number"
                          prefix={
                            <Icon
                              type="dollar"
                              style={{ color: 'rgba(0,0,0,.25)' }}
                            />
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                {ShopStore.currentShop.id === 374 && (
                  <Row gutter={8}>
                    <Col span={8}>
                      <Form.Item label="Warranty Start">
                        {getFieldDecorator('warrantyStart', {
                          initialValue: this.state.currentPart.warrantyStart,
                          rules: [],
                        })(
                          <DatePicker
                            format={'MM/DD/YYYY'}
                            style={{ width: '100%' }}
                            showTime
                            placeholder="Select start date"
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Warranty End">
                        {getFieldDecorator('warrantyEnd', {
                          initialValue: this.state.currentPart.warrantyEnd,
                          rules: [],
                        })(
                          <DatePicker
                            format={'MM/DD/YYYY'}
                            style={{ width: '100%' }}
                            showTime
                            placeholder="Select end date"
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Vendor">
                        {getFieldDecorator('vendor', {
                          initialValue: this.state.currentPart.vendor,
                          rules: [],
                        })(<Input type="text" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <Row gutter={8}>
                  <Col span={8}>
                    <Form.Item label="Aisle / Shelf / Bin">
                      {getFieldDecorator('aisle', {
                        initialValue: this.state.currentPart.aisle,
                        rules: [],
                      })(<Input type="text" />)}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item className="hide-label" label=" ">
                      {getFieldDecorator('shelf', {
                        initialValue: this.state.currentPart.shelf,
                        rules: [],
                      })(<Input type="text" />)}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item className="hide-label" label=" ">
                      {getFieldDecorator('bin', {
                        initialValue: this.state.currentPart.bin,
                        rules: [],
                      })(<Input type="text" />)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item label="Additional Notes:">
                      {getFieldDecorator('additionalNotes', {
                        initialValue: this.state.currentPart.additionalNotes,
                        rules: [],
                      })(<TextArea rows={4} />)}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col span={24}>
                    <Text style={{ marginBottom: 16 }}>
                      Add images (the first image uploaded will be used for the
                      preview up above):
                    </Text>
                    <UploaderWrapper>
                      {this.state.fileList.map((file) => {
                        const base64Image = file.originFileObj
                          ? URL.createObjectURL(file.originFileObj)
                          : file.url;
                        return (
                          <div
                            key={file.uid}
                            className={
                              file.status === 'error'
                                ? 'item has-error'
                                : 'item'
                            }
                          >
                            <img
                              src={base64Image}
                              alt="part"
                              style={{
                                width: '100%',
                                maxHeight: '100%',
                                objectFit: 'contain',
                              }}
                            />
                            <Button
                              className="remove-button"
                              size="small"
                              onClick={(event) =>
                                this.handleRemove(event, file.uid)
                              }
                              shape="circle"
                              icon="close"
                            />
                          </div>
                        );
                      })}
                      <Upload
                        accept="image/*"
                        listType="picture-card"
                        fileList={this.state.fileList}
                        onPreview={this.handlePreview}
                        onChange={this.handleChange}
                        beforeUpload={() => {
                          /* update state here */
                          return false;
                        }}
                        className="upload-list-inline"
                        showUploadList={false}
                      >
                        <ImageUploadWrapper>
                          <Icon type="plus" style={{ fontSize: 13 }} />
                          Upload
                        </ImageUploadWrapper>
                      </Upload>
                      {this.state.uploadErrorMessage && (
                        <div style={{ color: 'red' }}>
                          {this.state.uploadErrorMessage}
                        </div>
                      )}
                    </UploaderWrapper>
                    <Modal
                      visible={this.state.previewVisible}
                      footer={null}
                      onCancel={this.handleCancel}
                    >
                      <img
                        alt="example"
                        style={{ width: '100%' }}
                        src={this.state.previewImage}
                      />
                    </Modal>
                  </Col>
                </Row>
              </PitstopSimpleForm>
            </Col>
          </Row>
        </Wrapper>
      </Fragment>
    );
  }
}

const WrappedRegistrationForm = Form.create({ name: 'addPartForm' })(
  PartDetailsPage
);

export default observer(WrappedRegistrationForm);
