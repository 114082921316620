import React from 'react';

type TaxMisc = {
  type: 'absolute' | 'percentage';
  value: number;
};

type Vendor = {
  id: number;
  name: string;
};

type WorkOrderFormValues = {
  woInvoiceNumber: string;
  woCreatedBy: string;
  woSelectVehicle?: number;
  woAssetType: string;
  woAssignedTo: string;
  woStatus: string;
  woRepairType: string;
  woRepairPriority: string;
  woIssueReportDate: string;
  woStartDate?: moment.Moment | null;
  woCompletedDate?: moment.Moment | null;
  mileage?: number;
  engineHours?: number;
  vendor_id?: number;
  tax: TaxMisc;
  misc: TaxMisc;
  woSource: string;
  rawData: string;
  laborsTotal: number;
  partsTotal: number;
  costsTotal: number;
  wOid: string,
};

type NewPart = {
  name: string;
  description: string;
  number: string;
  cost: number;
};

interface IWorkOrderDetailsContext {
  state: {
    activeTab: string;
    lineItems: any[];
    shopVendors: Vendor[];
    currentCarIssues: any[];
    selectedCarId?: number;
    savingWorkOrder: false;
    currentWorkOrder: null;
    loadingWorkOrder: true;
    loadingWorkOrderLines: true;
    loadingInitial: true;
    title: string;
    initialFormValues: WorkOrderFormValues;
    shopUsers: any[];
    shopParts: any[];
    carDetails: any;
    newPart: NewPart;
    loadingSavingNewPart: boolean;
    currentLinePartIsBeingAdded?: any;
    uploadedFiles: any[];
    uploadedImages: any[];
    wo_images: any[];
    wo_documents: any[];
    showUploadButton: boolean;
    isUploadWorkOrderModalVisible: boolean;
    isLoadingWorkOrderUpload: boolean;
    importedInformation?: any;
    isDisabled: boolean;
    uploading: boolean;
    progress?: number;
    jsonGPT?: any;
  };
  shopUsers: any[];
  shopParts: any[];
  currentCarIssues: any[];
  vendorOptions: any[];
  updateWorkOrder: (values: WorkOrderFormValues) => void;
  onCarSelect: (carId: number) => void;
  onChangeAssignedTo: (value: string) => void;
  // line items
  onGetCarDetails: (carDetails: any) => void;
  onSelectAsmCode: (lineItemKey: number, asmCode: string) => void;
  onSelectSystemCode: (lineItemKey: number, systemCode: string) => void;
  // optional information
  setOptionalInformationValue: (key: string, value: any) => void;
}

const WorkOrderDetailsContext = React.createContext(
  {} as IWorkOrderDetailsContext
);

export const WorkOrderProvider = WorkOrderDetailsContext.Provider;
export const WorkOrderConsumer = WorkOrderDetailsContext.Consumer;

export default WorkOrderDetailsContext;
