import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment-timezone';

import { Tag } from 'antd';

import { CurrentUserStore, IssueStore, ShopStore } from 'stores';

import { customFleets } from 'shared';
import { showDemoVinOrRealVin } from 'shared/utils';
import { calcMileage, convertSecondsToHours } from 'helpers/unitCalculations';
import {
  getRoutineDueDate,
  getRoutineDueEngineHours,
  getRoutineDueMileage,
} from 'containers/IssueProfile/share/utils';


export const getGroupingRoutineService = (data) => {
  const getCategory = (name) => {
    if (
      name.indexOf('Inspect') !== -1 ||
      name.indexOf('inspect') !== -1 ||
      name.indexOf('Check') !== -1 ||
      name.indexOf('check') !== -1
    )
      return 'Inspect';

    if (name.indexOf('Replace') !== -1 || name.indexOf('replace') !== -1)
      return 'Replace';

    if (name.indexOf('Lubricate') !== -1 || name.indexOf('lubricate') !== -1)
      return 'Lubricate';

    if (name.indexOf('Change') !== -1 || name.indexOf('change') !== -1)
      return 'Change';

    return 'Other';
  };

  const getCategoryIndex = (category) => {
    switch (category) {
      case 'Inspect':
        return 1;
      case 'Lubricate':
        return 2;
      case 'Replace':
        return 3;
      case 'Change':
        return 4;
      case 'Other':
        return 5;
      default:
        return 0;
    }
  };

  let _data = Object.values(
    _.reduce(
      data,
      (prev, el) => {
        let issue = IssueStore.data.get(el);
        let category = getCategory(issue.getName());

        if (prev[category] === undefined) {
          return {
            ...prev,
            [category]: {
              index: getCategoryIndex(category),
              category,
              items: [el],
              key: category,
            },
          };
        } else {
          prev[category].items.push(el);
          return prev;
        }
      },
      {}
    )
  );

  _data = _.sortBy(_data, ['index']);

  return _data;
};

export const getVMRSCode = (item) => {
  let code;

  switch (item) {
    case 'Battery':
      code = '031';
      break;
    case 'Brakes':
      code = '013';
      break;
    case 'DEF fluid':
      code = '043';
      break;
    case 'Air Flow':
      code = '041';
      break;
    case 'ESR':
      code = '031';
      break;
    case 'fuel trim':
      code = '033';
      break;
    case '"cranking voltage':
      code = '032';
      break;
    case 'starter':
      code = '032';
      break;
    default:
      code = '900';
  }

  return code;
};

export const getVMRSText = (item) => {
  let text;

  switch (item) {
    case 'Battery':
      text = 'CRANKING SYSTEM';
      break;
    case 'Brakes':
      text = 'BRAKE SYSTEM';
      break;
    case 'DEF fluid':
      text = 'EXHAUST SYSTEM';
      break;
    case 'Air Flow':
      text = 'AIR INTAKE SYSTEM';
      break;
    default:
      text = 'OTHER';
  }

  return text;
};

export const convertVMRSCode = (n) => {
  if (!n) return '900';
  if (n / 10 < 1) return `00${n}`;
  if (n / 10 > 10 && n / 10 < 100) return n;

  return `0${n}`;
};

export const getColumnsBySource = (source, page = null) => {
  let columns = [
    {
      title: 'Priority',
      key: 'priority',
      width: 100,
      sorter: true,
      render: (issue) => {
        return (
          <Tag color={issue.priorityTagColor}>{issue.priorityCategory}</Tag>
        );
      },
    },
    {
      title: 'Source',
      key: 'source',
      render: (issue) => issue.source,
    },
    {
      title: 'Issue Name',
      key: 'name',
      render: (issue) => issue.description,
    }
  ];

  if (page !== 'carPage') {
    columns.push({
      title: 'Unit',
      key: 'carName',
      sorter: true,
      render: (issue) => {
        const { name, vin, id, engineHours, mileageTotal } = issue.car;

        return (
          <>
            <Link to={`/car/${id}`}>
              {name ? name : 'N/A'} <br />
              {vin ? showDemoVinOrRealVin(vin) : 'N/A'} <br />
            </Link>

            <>
              {!_.isNaN(engineHours) && !customFleets.motiveFleets.includes(ShopStore.currentShop.id)
                ? `${Math.round(
                    convertSecondsToHours(engineHours)
                  ).toReadable()} engine hours`
                : ''}

              <br />

              {!_.isNaN(mileageTotal) && !customFleets.motiveFleets.includes(ShopStore.currentShop.id)
                ? `${Math.round(calcMileage(mileageTotal)).toReadable()} ${
                    CurrentUserStore.user.displayedOdometer
                  }`
                : ''}
            </>
          </>
        );
      },
    });
  }

  columns = [
    ...columns,
    // {
    //   title: 'VIN',
    //   key: 'car',
    //   render: (issue) => {
    //     const { vin, id, engineHours, mileageTotal } = issue.car;

    //     return (
    //       <>
    //         <Link to={`/car/${id}`}>
    //           {vin ? showDemoVinOrRealVin(vin) : 'N/A'} <br />
    //         </Link>

    //         <>
    //           {!_.isNaN(engineHours)
    //             ? `${Math.round(
    //                 convertSecondsToHours(engineHours)
    //               ).toReadable()} engine hours`
    //             : ''}

    //           <br />

    //           {!_.isNaN(mileageTotal)
    //             ? `${Math.round(calcMileage(mileageTotal)).toReadable()} ${
    //                 CurrentUserStore.user.displayedOdometer
    //               }`
    //             : ''}
    //         </>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: (
    //     <>
    //       Active Time&nbsp;
    //       <Tooltip title={titles.dtc}>
    //         <Icon type={'question-circle-o'} />
    //       </Tooltip>
    //     </>
    //   ),
    //   key: 'activeTime',
    //   render: (issue) => {
    //     const activeTimeSeconds = issue.activeTimeSeconds;
    //     if (activeTimeSeconds === null) {
    //       return 'N/A';
    //     }
    //     if (typeof activeTimeSeconds !== 'number') {
    //       return '';
    //     }
    //     return humanizeDuration(activeTimeSeconds * 1000);
    //   },
    // },
    // {
    //   title: 'VMRS Sys. Code',
    //   key: 'vmrsSys',
    //   render: (issue) => {
    //     let sys = issue.vmrs
    //       ? convertVMRSCode(issue.vmrs.sys)
    //       : getVMRSCode(issue.item);

    //     let text = issue.vmrs ? issue.vmrs.text : getVMRSText(issue.item);

    //     return <Tooltip title={text}>{`${sys}`}</Tooltip>;
    //   },
    // },
    {
      title: 'Trigger Time',
      key: 'firstTriggeredAt',
      sorter: true,
      render: (issue) => {
        const firstTriggeredAtDate =
          issue?.firstTriggeredAt ||
          issue?.report?.date ||
          issue?.createdAt ||
          issue?.reportDate;

        const formattedFirstTriggeredAtDate = moment.tz(firstTriggeredAtDate,
          CurrentUserStore.user.settings.timezone
        ).format('h:mA, Do MMM');

        let lastTriggeredAt;

        if (issue.status === 'done' && issue?.doneDate) {
          lastTriggeredAt = issue?.doneDate;
        } else {
          lastTriggeredAt =
            issue?.lastTriggeredAt ||
            issue?.report?.date ||
            issue?.updatedAt ||
            issue?.reportDate;
        }

        const formattedLastTriggeredAtDate = moment.tz(lastTriggeredAt,
          CurrentUserStore.user.settings.timezone
        ).format('h:mA, Do MMM');

        if (
          (issue?.triggerCount || 1) === 1
          || (formattedFirstTriggeredAtDate === formattedLastTriggeredAtDate)
        ) {
          return (
            <>
              <strong>Last -</strong> {formattedLastTriggeredAtDate}
            </>
          );
        }

        return (
          <>
            <strong>First -</strong> {formattedFirstTriggeredAtDate}<br />
            <strong>Last -</strong> {formattedLastTriggeredAtDate}
          </>
        );
      },
    },
    // {
    //   title: 'Last Triggered At',
    //   key: 'lastTriggeredAt',
    //   render: (issue) => {
    //     if (issue.status === 'done' && issue?.doneDate) {
    //       return moment
    //         .tz(issue?.doneDate, CurrentUserStore.user.settings.timezone)
    //         .format('lll');
    //     }

    //     const updatedAt =
    //       issue?.lastTriggeredAt ||
    //       issue?.report?.date ||
    //       issue?.updatedAt ||
    //       issue?.reportDate;

    //     return moment
    //       .tz(updatedAt, CurrentUserStore.user.settings.timezone)
    //       .format('lll');
    //   },
    // },
    {
      title: 'Trigger Count',
      key: 'triggerCount',
      render: (issue) => {
        return issue?.triggerCount || 1;
      },
    }
  ];

  if (!_.includes(['all'], source)) {
    columns = _.filter([...columns], (col) => col.key !== 'source');
  }

  if (!_.includes(['all', 'dtc'], source)) {
    columns = _.filter([...columns], (col) => col.key !== 'activeTime');
  }

  if (source === 'routine' || source === 'all') {
    columns = [
      ...columns,
      {
        title: 'Due',
        key: 'dueDate',
        sorter: true,
        render: (issue) => {
          if (issue.routineInfo) {
            let messages = [];

            if (getRoutineDueDate(issue))
              messages.push(
                <>
                  <b>On Date: </b>
                  {`${getRoutineDueDate(issue)}`}
                </>
              );

            if (getRoutineDueEngineHours(issue))
              messages.push(
                <>
                  <b>On Engine Hours:</b>{' '}
                  {`${Number(getRoutineDueEngineHours(issue)).toReadable()}`}{' '}
                  engine hours
                </>
              );

            if (getRoutineDueMileage(issue))
              messages.push(
                <>
                  <b>On Mileage: </b>{' '}
                  {`${Number(getRoutineDueMileage(issue)).toReadable()} ${
                    CurrentUserStore.user.displayedOdometer
                  }`}
                </>
              );

            if (_.isEmpty(messages)) {
              return 'N/A';
            }

            return _.map(messages, (msg) => (
              <>
                {msg}
                <br />
              </>
            ));
          }
          return 'N/A';
        },
      },
    ];
  }

  columns = [
    ...columns,
    // {
    //   title: 'Actions',
    //   key: 'actions',
    //   width: '180px',
    //   render: (issue) => {
    //     return (
    //       <>
    //         {issue.status === 'new' && (
    //           <MarkAsCompleteBtn issue={issue} onClick={() => {}} />
    //         )}

    //         {['waitingForApproval'].includes(
    //           issue.status
    //         ) && <MarkAsCompleteBtn issue={issue} onClick={() => {}} />}

    //         {['done', 'waitingForApproval', 'upcoming'].includes(
    //           issue.status
    //         ) && <MarkAsCurrentBtn issue={issue} onClick={() => {}} />}
    //       </>
    //     );
    //   },
    //   rowClick: false,
    // },
  ];

  return _.filter(columns, Boolean);
};

export const convertSource = (source) => {
  if (_.isNil(source)) return 'all';

  if (source === 'faultCode') return 'dtc';

  if (source === 'pitstopSuggested') return 'algorithm';

  return source;
};
