import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { DealershipChooser } from 'components';
import {
  Form,
  InputNumber,
  Button,
  Card,
  Col,
  Row,
  Spin,
  notification,
  Table,
  Typography,
} from 'antd';
import styled from 'styled-components';
import { observe } from 'mobx';
import { ShopStore } from 'stores';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { get } from 'lodash';
import NotificationBanner from 'components/NotificationBanner';

const H2Styled = styled.h2`
  font-size: 1.2rem;
`;

class FleetPMQualityPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object,
  };

  state = {
    isLoadingVehiclePMQuality: false,
    isLoadingScatterVehiclePMQuality: false,
    dataForVehiclePMQuality: [],
    dataForScatterVehiclePMQuality: [],
    limit: 20, // New: Add limit to state
    rawData: [],
    UnitIdData: [],
    detailedData: [], // New: Add detailed data to state
    showTable: false, // New: Add flag to show/hide table
  };

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    this.disposer();
  }

  disposer = observe(ShopStore.currentShop, 'id', () => {
    this.init();
  });

  loadDataForVehiclePMQuality = async () => {
    // of interest
    try {
      this.setState({ isLoadingVehiclePMQuality: true });
      const { limit } = this.state; // New: Destructure limit from state
      const response = await ShopStore.getShopDataForVehiclePMQuality(
        ShopStore.currentShop.id,
        limit // New: Pass limit as parameter
      );
      const data = get(response, 'data', []);
      const frequencyCounts = new Array(30).fill(0);

      data.forEach((item) => {
        const dateDiffs = get(item.pm_quality_data, 'date_diffs', []);
        dateDiffs.forEach((value) => {
          if (value >= 1 && value <= 30) {
            frequencyCounts[value - 1] += 1;
          }
        });
      });

      this.setState({
        rawData: data,
        dataForVehiclePMQuality: Array.isArray(frequencyCounts)
          ? frequencyCounts
          : [],
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message,
      });
      this.setState({ dataForVehiclePMQuality: [] });
    } finally {
      this.setState({ isLoadingVehiclePMQuality: false });
    }
  };

  loadDataForScatterVehiclePMQuality = async () => {
    // of interest
    try {
      this.setState({ isLoadingVehiclePMQuality: true });
      const { limit } = this.state; // New: Destructure limit from state
      const response = await ShopStore.getShopDataForVehiclePMQuality(
        ShopStore.currentShop.id,
        limit // New: Pass limit as parameter
      );
      const data = get(response, 'data', []);

      const idCars = Array.isArray(data) ? data.map((item) => item.id_car) : [];
      const response2 = await ShopStore.getUnitIDsFromCarIds(idCars);
      const carNamesData = get(response2, 'data', []);

      // Create a mapping of car IDs to car names
      const carNamesMap = {};
      carNamesData.forEach((car) => {
        carNamesMap[car.id] = car.car_name;
      });

      const processedData = [];
      const yAxisCategories = data.map((item) => carNamesMap[item.id_car]);

      data.forEach((item, index) => {
        const vehicleId = item.id_car;
        const dateDiffs = get(item.pm_quality_data, 'date_diffs', []);
        dateDiffs.forEach((diff) => {
          processedData.push({
            x: diff,
            y: index,
            vehicleId: vehicleId,
            carName: carNamesMap[vehicleId],
          });
        });
      });

      this.setState({
        UnitIdData: carNamesMap,
        dataForScatterVehiclePMQuality: Array.isArray(processedData)
          ? processedData
          : [],
        vehicles: yAxisCategories,
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message,
      });
      this.setState({ dataForScatterVehiclePMQuality: [] });
    } finally {
      this.setState({ isLoadingScatterVehiclePMQuality: false });
    }
  };

  init = async () => {
    // load data for Vehicle PM Analysis
    this.loadDataForVehiclePMQuality();
    // load data for Scatter Vehicle PM Analysis
    this.loadDataForScatterVehiclePMQuality();
  };

  handleFilterChange = (changedFields) => {
    // New: Handle filter changes
    this.setState(changedFields);
  };

  handleApplyFilters = () => {
    // New: Apply filters
    this.init();
  };

  handleBarClick = (event) => {
    // New: Handle bar click
    const { point } = event;
    const { rawData, UnitIdData } = this.state;

    if (!rawData) {
      return;
    }

    const detailedData = rawData
      .filter((item) => {
        return item.pm_quality_data.date_diffs.includes(point.x + 1);
      })
      .flatMap((item) => {
        // Calculate occurrences of point.x + 1 in item.pm_quality_data.date_diffs
        let numOccurrences = item.pm_quality_data.date_diffs.reduce(
          (count, currentValue) => {
            if (currentValue === point.x + 1) {
              count++;
            }
            return count;
          },
          0
        );

        return item.pm_quality_data.date_diffs.includes(point.x + 1)
          ? [
              {
                days_after_pm: point.x + 1,
                num_cases: numOccurrences, // need to replace ? here with number of occurences of point.x+1 in item.pm_quality_data.date_diffs
                unit_id: UnitIdData[item.id_car],
              },
            ]
          : [];
      });

    this.setState({ detailedData, showTable: true });
  };

  render() {
    const {
      dataForVehiclePMQuality,
      dataForScatterVehiclePMQuality,
      vehicles,
      limit,
      isLoadingVehiclePMQuality,
      isLoadingScatterVehiclePMQuality,
      detailedData,
      showTable,
    } = this.state;

    const vehiclePMQualityChartOptions = {
      chart: {
        type: 'column',
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: Array.from({ length: 30 }, (_, i) => i + 1),
        crosshair: true,
        title: {
          text: 'No. of Days After PM Service Breakdown Occurred',
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: 'No. of Cases',
        },
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: (event) => {
                // Get the series and reset all bar colors
                const data = get(event.point.series, 'data', []);
                data.forEach((point) => {
                  point.update({ color: null }, false);
                });

                // Highlight the clicked bar
                event.point.update({ color: '#ff0000' }, true);

                // Call the handleBarClick function with the appropriate context
                this.handleBarClick(event);
              },
            },
          },
          dataLabels: {
            enabled: true,
          },
        },
      },
      series: [
        {
          name: 'No. of Cases',
          colors: [
            '#692c2c',
            '#712e2e',
            '#7a3030',
            '#823232',
            '#8a3434',
            '#923636',
            '#9b3838',
            '#a23b3b',
            '#ab3c3c',
            '#ab3c3c',
            '#c5804a',
            '#c8844e',
            '#c8844e',
            '#cd8b57',
            '#cf905b',
            '#d2935f',
            '#d59764',
            '#d79b68',
            '#da9f6c',
            '#dca270',
            '#dfa675',
            '#e1aa79',
            '#e3ac7c',
            '#e6b281',
            '#e9b586',
            '#ebb98a',
            '#eebd8e',
            '#f1c192',
            '#f3c497',
            '#f6c99c',
          ],
          colorByPoint: true,
          data: dataForVehiclePMQuality, // Adjusted field name
        },
      ],
    };

    const vehicleScatterPMQualityChartOptions = {
      chart: {
        type: 'scatter',
        zoomType: 'xy', // Enable zooming on both axes
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: Array.from({ length: 30 }, (_, i) => i + 1),
        title: {
          text: 'No. of Days After PM Service Breakdown Occurred',
        },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
      },
      yAxis: {
        categories: vehicles,
        title: {
          text: 'Vehicle Unit ID',
        },
        tickmarkPlacement: 'on',
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
      },
      plotOptions: {
        scatter: {
          jitter: {
            x: 0.25, // Adjust jitter intensity as needed
            y: 0.1,
          },
          marker: {
            radius: 5,
            fillColor: 'rgba(24, 60, 188, 0.3)', // Set fill opacity to make points slightly transparent
            lineWidth: 0.5, // Adjust the outline width as needed
            lineColor: '#000000', // Black color outline
          },
          point: {
            events: {
              click: function() {
                const customUrl = `https://dashboard.pitstopconnect.com/car/${this.options.vehicleId}`;
                window.open(customUrl, '_blank');
              },
            },
          },
        },
      },
      tooltip: {
        pointFormat: 'Unit ID: {point.carName}', // Show the id_car on hover
      },
      series: [
        {
          name: 'Vehicle',
          color: '#183cbc',
          turboThreshold: 10000,
          data: dataForScatterVehiclePMQuality.map((point) => ({
            x: point.x,
            y: point.y,
            vehicleId: point.vehicleId,
            carName: point.carName || `Car ID: ${point.vehicleId}`, // Ensure carName is available
          })),
        },
      ],
    };

    // check if not empty and at least one data point is different than 0
    const hasDataForFleetWidePMAnalysis =
      dataForVehiclePMQuality.length > 0 &&
      dataForVehiclePMQuality.some((value) => value !== 0);

    const hasDataForVehiclePMAnalysis =
      dataForScatterVehiclePMQuality.length > 0 &&
      dataForScatterVehiclePMQuality.some((value) => value !== 0);

    const columns = [
      {
        title: 'Days After PM',
        dataIndex: 'days_after_pm',
        key: 'days_after_pm',
      },
      {
        title: 'No. Of Cases',
        dataIndex: 'num_cases', // formerly car_id
        key: 'num_cases',
      },
      {
        title: 'Unit ID',
        dataIndex: 'unit_id',
        key: 'unit_id',
      },
    ];

    return (
      <Fragment>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <h1>Quality of PM Analysis</h1>
          </Col>
        </Row>
        <DealershipChooser />
        <Form layout="inline" onValuesChange={this.handleFilterChange}>
          {' '}
          {/* New: Add form for filters */}
          <Form.Item label="Limit">
            <InputNumber
              min={1}
              defaultValue={20}
              value={limit}
              onChange={(value) => this.handleFilterChange({ limit: value })}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={this.handleApplyFilters}>
              Apply Filters
            </Button>{' '}
            {/* New: Apply Filters button */}
          </Form.Item>
        </Form>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Spin spinning={isLoadingVehiclePMQuality}>
              <Card>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <H2Styled>PM Quality Fleet-wide Analysis</H2Styled>
                  </Col>
                  <Col span={24}>
                    {hasDataForFleetWidePMAnalysis ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={vehiclePMQualityChartOptions}
                      />
                    ) : (
                      <NotificationBanner
                        message={
                          <>
                            <Typography.Text>No data available</Typography.Text>
                          </>
                        }
                        hideClose={true}
                      />
                    )}
                  </Col>
                </Row>
              </Card>
            </Spin>
          </Col>
          <Col span={12}>
            <Spin spinning={isLoadingScatterVehiclePMQuality}>
              <Card>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <H2Styled>PM Quality Vehicle Analysis</H2Styled>
                  </Col>
                  <Col span={24}>
                    {hasDataForVehiclePMAnalysis ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={vehicleScatterPMQualityChartOptions}
                      />
                    ) : (
                      <NotificationBanner
                        message={
                          <>
                            <Typography.Text>No data available</Typography.Text>
                          </>
                        }
                        hideClose={true}
                      />
                    )}
                  </Col>
                </Row>
              </Card>
            </Spin>
          </Col>
        </Row>
        {showTable && (
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Card>
                <Table dataSource={detailedData} columns={columns} />
              </Card>
            </Col>
          </Row>
        )}
      </Fragment>
    );
  }
}

export default withRouter(observer(FleetPMQualityPage));
