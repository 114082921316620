import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';
import _ from 'lodash';

import {
  DatePicker,
  Form,
  Input,
  Radio,
  AutoComplete,
  InputNumber,
  Row,
  Col,
  Icon,
  Button,
} from 'antd';

import { partList } from './data/general';

import { webServiceProvider } from 'shared';
import { convertDistanceValueToKMorMiles } from 'helpers/unitCalculations';

import { AppStore, ShopStore, CurrentUserStore, CarStore } from 'stores';
import { SelectCars } from 'containers/Users';
// import RoutinesTableList from 'containers/Maintenance/RoutinesTableList';

const RoutineContainer = styled.div`
  & > div {
    margin: 0px;
  }
  margin-bottom: 24px;

  .routineBased {
    font-weight: 500;
  }

  .ant-row {
    margin: 0px;
  }
`;

class RoutineServiceAntdForm extends Component {
  state = {
    selectedCarIds: [],
    carInfo: undefined,
    action: null,
    partName: null,
    description: null,
    priority: null,
    isTimeAlertOn: false,
    isTimeIntervalAlertOn: true,
    isMileageAlertOn: false,
    isEngineHoursAlertOn: false,
    isEngineHoursIntervalAlertOn: true,
    currentUnit: CurrentUserStore.user.displayedOdometer,
    timeAlert: {
      started: moment().format('YYYY-MM-DD'),
      interval: 1,
      threshold: 1,
    },
    mileageAlert: { started: 1, interval: 1, threshold: 1 },
    engineHoursAlert: { started: 1, interval: 1, threshold: 1 },
  };

  disposer = observe(ShopStore.currentShop, 'id', () => {
    this.setState({
      selectedCarIds: [],
    });
  });

  componentWillUnmount() {
    this.disposer();
  }

  onSelect = async (ids) => {
    const carInfo = await CarStore.data.get(ids[0]);
    this.setState(
      {
        selectedCarIds: ids,
        carInfo: carInfo,
      },
      () => {
        console.log(
          "car Info updated:",
          this.state.carInfo.totalMileage,
          this.state.carInfo.engineHours
        );

        const { form } = this.props;

        // Check if mileage alert is selected and reset started_mileage
        if (this.state.isMileageAlertOn) {
          form.setFieldsValue({
            started_mileage: Math.ceil(
              convertDistanceValueToKMorMiles(
                Number(this.state.carInfo?.totalMileage),
                'km',
                this.state.currentUnit
              )
            ) || 1,
          });
        }

        // Check if engine hours alert is selected and reset started_engine_hours
        if (this.state.isEngineHoursAlertOn) {
          form.setFieldsValue({
            started_engine_hours: Math.ceil(
              this.state.carInfo?.engineHours / 3600 // Convert seconds to hours
            ) || 1,
          });
        }
      }
    );
  };

  resetFormFields = () => this.props.form.resetFields();

  onSubmit = () => {
    const {
      isTimeAlertOn,
      isEngineHoursAlertOn,
      isMileageAlertOn,
      selectedCarIds,
    } = this.state;

    this.props.form.validateFields(async (err, values) => {
      try {
        if (ShopStore.currentShop.id === -1) {
          throw Error('Please select another shop!');
        }

        if (err) {
          throw Error('Some fields in the form are required!');
        }

        if (_.isEmpty(selectedCarIds)) {
          throw Error('Please select vehicles!');
        }

        if (!isTimeAlertOn && !isMileageAlertOn && !isEngineHoursAlertOn) {
          throw Error('Please Select At Least One Alert!');
        }

        AppStore.addLoading('Service is being created...');

        await this.onAddService();

        AppStore.addSuccess('Service has been created!');
      } catch (err) {
        AppStore.addError(`Error in adding routine service: ${err.message}`);
      } finally {
        AppStore.removeLoading('Service is being created...');
      }
    });
  };

  onAddService = async () => {
    let {
      selectedCarIds,
      action,
      partName,
      description,
      priority,
      timeAlert,
      mileageAlert,
      engineHoursAlert,
      isTimeAlertOn,
      isMileageAlertOn,
      isEngineHoursAlertOn,
    } = this.state;


    let rule_type = isTimeAlertOn ? 'time' :
                    isMileageAlertOn ? 'mileage' :
                    isEngineHoursAlertOn ? 'engine_hours' :
                    undefined; // default

    const started_time = moment(this.props.form.getFieldValue('started_time')).unix() || moment(timeAlert.started).unix;
    const started_mileage = this.props.form.getFieldValue('started_mileage') || mileageAlert.started;
    const started_engine_hours = this.props.form.getFieldValue('started_engine_hours') || engineHoursAlert.started;

    let actualCarInfo = [];
    for (const carId of selectedCarIds) {
      const carInfo = await CarStore.data.get(carId);
      if(rule_type === 'time') {
        actualCarInfo.push({carId, time: new Date()});
      } else if(rule_type === 'mileage') {
        actualCarInfo.push({carId, mileage: Number(carInfo.totalMileage)});
      } else if(rule_type === 'engine_hours') {
        actualCarInfo.push({carId, engineHours: carInfo.engineHours});
      } else {
        actualCarInfo.push({carId, mileage: Number(carInfo.totalMileage), engineHours: carInfo.engineHours});
      }
    }

    // Convert months to seconds
    let interval_time = 0;
    if (isTimeAlertOn && timeAlert.interval) {
      const duration = moment.duration(Number(timeAlert.interval), 'months');
      interval_time = duration.asSeconds();
    }

    let data = {
      shopId: ShopStore.currentShop.id,
      data: {
        action: action,
        item: partName,
        description: description || 'No description available',
        priority: priority,
        status: 'new',
        actualCarInfo,
        rule_type,
        interval_time:
          isTimeAlertOn && timeAlert.interval
            ? Number(interval_time)
            : null,
        started_time:
          isTimeAlertOn
            ? started_time
            : null,
        interval_mileage:
          isMileageAlertOn && Number(mileageAlert.interval)
            ? Math.ceil(convertDistanceValueToKMorMiles(Number(mileageAlert.interval), this.state.currentUnit, 'km'))
            : null,
        started_mileage:
          isMileageAlertOn
            ? Math.ceil(convertDistanceValueToKMorMiles(Number(started_mileage), this.state.currentUnit, 'km'))
            : null,
        interval_engine_hours:
          isEngineHoursAlertOn &&
          engineHoursAlert.interval
            ? Number(engineHoursAlert.interval) * 3600 // In seconds
            : null,
        started_engine_hours:
          isEngineHoursAlertOn
            ? Number(started_engine_hours) * 3600 // In seconds
            : null,
        threshold: {
          time:
            isTimeAlertOn && timeAlert.threshold ? timeAlert.threshold : null,
          mileage:
            isMileageAlertOn && mileageAlert.threshold
              ? Math.ceil(convertDistanceValueToKMorMiles(Number(mileageAlert.threshold), this.state.currentUnit, 'km'))
              : null,
          engineHours:
            isEngineHoursAlertOn && engineHoursAlert.threshold
              ? engineHoursAlert.threshold * 3600
              : null,
        },
      },
      carIds: selectedCarIds,
    };

    // route that create the routine
    await webServiceProvider.post('v1/car/newPm', data);
  };

  getTimeAlertText = () => {
    let { isTimeAlertOn, timeAlert } = this.state;

    if (!isTimeAlertOn) return '';

    if (!_.isNil(timeAlert.interval)) {
      return (
        <p>
          This service will be created every{' '}
          <b>(Z) {`${timeAlert.interval}`} months</b> start from{' '}
          <b>(X) {`${timeAlert.started}`}</b> and alert{' '}
          <b>(Y) {`${timeAlert.threshold}`} days</b> before
        </p>
      );
    }
  };

  getMileageAlertText = () => {
    let {
      isMileageAlertOn,
      mileageAlert,
    } = this.state;

    if (!isMileageAlertOn) return '';

    if (!_.isNil(mileageAlert.interval)) {
      return (
        <p>
          This service will be created every{' '}
          <b>
            (Y){' '}
            {`${mileageAlert.interval} ${CurrentUserStore.user.displayedOdometer}`}
          </b>{' '}
          and alert{' '}
          <b>
            (Z){' '}
            {`${mileageAlert.threshold} ${CurrentUserStore.user.displayedOdometer}`}
          </b>{' '}
          before
        </p>
      );
    }
  };

  getEngineHoursAlertText = () => {
    let {
      isEngineHoursAlertOn,
      engineHoursAlert,
    } = this.state;

    if (!isEngineHoursAlertOn) return '';

    if (!_.isNil(engineHoursAlert.interval)) {
      return (
        <p>
          This service will be created every{' '}
          <b>(Y) {`${engineHoursAlert.interval}`} engine hours</b> and alert{' '}
          <b>(z) {`${engineHoursAlert.threshold}`} engine hours</b> before
        </p>
      );
    }
  };

  render() {
    const { selectedCarIds } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form layout="vertical">
              <Form.Item label="Action / Service Name">
                {getFieldDecorator('action', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input a Service name!',
                    },
                  ],
                })(
                  <AutoComplete
                    type="textarea"
                    dataSource={[
                      'Change',
                      'Inspect',
                      'Perform',
                      'Replace',
                      'Remind',
                      'Rotate',
                    ]}
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onChange={(val) =>
                      this.setState({
                        action: val,
                      })
                    }
                  />
                )}
              </Form.Item>

              <Form.Item label="Part Name">
                {getFieldDecorator('partName', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input a Part Name!',
                    },
                  ],
                })(
                  <AutoComplete
                    type="textarea"
                    dataSource={partList}
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onChange={(val) =>
                      this.setState({
                        partName: val,
                      })
                    }
                  />
                )}
              </Form.Item>

              <Form.Item label="Description">
                {getFieldDecorator('description')(
                  <Input.TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    onChange={(e) => {
                      this.setState({
                        description: e.target.value,
                      });
                    }}
                  />
                )}
              </Form.Item>

              <Form.Item label="Priority">
                {getFieldDecorator('priority', {
                  rules: [
                    {
                      required: true,
                    },
                  ],
                })(
                  <Radio.Group
                    size="large"
                    onChange={(e) => {
                      let priority;

                      switch (e.target.value) {
                        case 'Minor':
                          priority = 1;
                          break;
                        case 'Major':
                          priority = 3;
                          break;
                        case 'Critical':
                          priority = 4;
                          break;
                        default:
                          priority = 4;
                      }

                      this.setState({
                        priority: priority,
                      });
                    }}
                  >
                    <Radio value="Minor" size="large">
                      <p
                        style={{
                          backgroundColor: '#dec431',
                          color: 'white',
                          display: 'inline',
                          padding: '5px',
                          borderRadius: '3px',
                        }}
                      >
                        Minor
                      </p>
                    </Radio>

                    <Radio value="Major" size="large">
                      <p
                        style={{
                          backgroundColor: '#e28931',
                          color: 'white',
                          display: 'inline',
                          padding: '5px',
                          borderRadius: '3px',
                        }}
                      >
                        Major
                      </p>
                    </Radio>

                    <Radio value="Critical" size="large">
                      <p
                        style={{
                          backgroundColor: '#f92d2e',
                          color: 'white',
                          display: 'inline',
                          padding: '5px',
                          borderRadius: '3px',
                        }}
                      >
                        Critical
                      </p>
                    </Radio>
                  </Radio.Group>
                )}
              </Form.Item>

              <>
                <RoutineContainer>
                  <Form.Item className="routineBased" label="Set Time Alert ?">
                    <Radio.Group
                      buttonStyle="solid"
                      size="small"
                      name="timeBased"
                      value={this.state.isTimeAlertOn ? 'yes' : 'no'}
                      onChange={() =>
                        this.setState({
                          isTimeAlertOn: !this.state.isTimeAlertOn,
                          isTimeIntervalAlertOn: false,
                          isMileageAlertOn: false,
                          isEngineHoursAlertOn: false
                        })
                      }
                    >
                      <Radio.Button value="no">No</Radio.Button>
                      <Radio.Button value="yes">Yes</Radio.Button>
                    </Radio.Group>
                  </Form.Item>

                  {this.state.isTimeAlertOn && (
                    <>
                      <Row>
                        <Col span={6}>
                          <Form.Item label="Service Start On (X)?">
                            {getFieldDecorator('started_time', {
                              initialValue: moment(),
                              rules: [
                                { required: true, message: 'This is required' },
                              ],
                            })(
                              <DatePicker
                                onChange={(date) =>
                                  this.setState({
                                    timeAlert: {
                                      ...this.state.timeAlert,
                                      started: moment(date).format(
                                        'YYYY-MM-DD'
                                      ),
                                    },
                                  })
                                }
                              />
                            )}
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Form.Item label="Service Repeat Every (Y) Months?">
                            <div style={{ display: 'flex' }}>
                              {getFieldDecorator('interval_time', {
                                initialValue: 1,
                                rules: [
                                  {
                                    required: true,
                                    type: 'number',
                                    message:
                                      'This is required and must be a number!',
                                  },
                                ],
                              })(
                                <InputNumber
                                  min={0}
                                  onChange={(val) => {
                                    let key = 'interval';
                                    this.setState({
                                      timeAlert: {
                                        ...this.state.timeAlert,
                                        [`${key}`]: val,
                                      },
                                    })
                                  }}
                                />
                              )}

                              <p
                                style={{
                                  marginLeft: 10,
                                  marginTop: 5,
                                  fontWeight: 500,
                                }}
                              >
                                Months
                              </p>
                            </div>
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Form.Item label="Alert Service Before (Z) Days?">
                            <div style={{ display: 'flex' }}>
                              {getFieldDecorator('thresholdTime', {
                                initialValue: 1,
                                rules: [
                                  {
                                    required: true,
                                    type: 'number',
                                    message:
                                      'This is required and must be a number!',
                                  },
                                ],
                              })(
                                <InputNumber
                                  min={0}
                                  onChange={(val) =>
                                    this.setState({
                                      timeAlert: {
                                        ...this.state.timeAlert,
                                        threshold: val,
                                      },
                                    })
                                  }
                                />
                              )}

                              <p
                                style={{
                                  marginLeft: 10,
                                  marginTop: 5,
                                  fontWeight: 500,
                                }}
                              >
                                days
                              </p>
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24} style={{ display: 'flex' }}>
                          <Icon
                            style={{
                              fontSize: 14,
                              marginRight: 8,
                              marginTop: 4,
                            }}
                            type="info-circle"
                          />
                          <i> {this.getTimeAlertText()}</i>
                        </Col>
                      </Row>
                    </>
                  )}
                </RoutineContainer>

                <RoutineContainer>
                  <Form.Item
                    className="routineBased"
                    label="Set Mileage Alert?"
                  >
                    <Radio.Group
                      buttonStyle="solid"
                      size="small"
                      name="mileageBased"
                      value={this.state.isMileageAlertOn ? 'yes' : 'no'}
                      onChange={() =>
                        this.setState({
                          isMileageAlertOn: !this.state.isMileageAlertOn,
                          isTimeAlertOn: false,
                          isEngineHoursAlertOn: false
                        })
                      }
                    >
                      <Radio.Button value="no">No</Radio.Button>
                      <Radio.Button value="yes">Yes</Radio.Button>
                    </Radio.Group>
                  </Form.Item>

                  {this.state.isMileageAlertOn && (
                    <>
                      <Row>
                        <Col span={6}>
                          <Form.Item
                            label={
                              <span>
                                Service Start at (X) Mileage?
                                <br />
                                <span style={{ fontWeight: 'normal', color: '#888' }}>
                                  (Current Mileage as Default)
                                </span>
                              </span>
                            }
                            name="mileage"
                          >
                            <div style={{ display: 'flex' }}>
                              {getFieldDecorator('started_mileage',
                                {
                                  initialValue: Math.ceil(convertDistanceValueToKMorMiles(Number(this.state.carInfo?.totalMileage), 'km', this.state.currentUnit)) || 1,
                                  rules: [
                                    {
                                      required: true,
                                      type: 'number',
                                      message:
                                        'This is required and must be a number!',
                                    },
                                  ],
                                }
                              )(
                                <InputNumber
                                  min={0}
                                  onChange={(val) => {
                                    let key = 'started';
                                    this.setState({
                                      mileageAlert: {
                                        ...this.state.mileageAlert,
                                        [`${key}`]: val,
                                      },
                                    });
                                  }}
                                />
                              )}

                              <p
                                style={{
                                  marginLeft: 10,
                                  marginTop: 5,
                                  fontWeight: 500,
                                }}
                              >
                                {CurrentUserStore.user.displayedOdometer}
                              </p>
                            </div>
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Form.Item
                            label='Service Repeat Every (Y) Mileage?'
                            name="mileage"
                          >
                            <div style={{ display: 'flex', marginTop: '21px' }}>
                              {getFieldDecorator('interval_mileage',
                                {
                                  initialValue: 1,
                                  rules: [
                                    {
                                      required: true,
                                      type: 'number',
                                      message:
                                        'This is required and must be a number!',
                                    },
                                  ],
                                }
                              )(
                                <InputNumber
                                  min={0}
                                  onChange={(val) => {
                                    let key = 'interval';
                                    this.setState({
                                      mileageAlert: {
                                        ...this.state.mileageAlert,
                                        [`${key}`]: val,
                                      },
                                    });
                                  }}
                                />
                              )}

                              <p
                                style={{
                                  marginLeft: 10,
                                  marginTop: 5,
                                  fontWeight: 500,
                                }}
                              >
                                {CurrentUserStore.user.displayedOdometer}
                              </p>
                            </div>
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Form.Item label="Alert Service Before (Z) Mileage?">
                            <div style={{ display: 'flex', marginTop: '21px' }}>
                              {getFieldDecorator('thresholdMiles', {
                                initialValue: 1,
                                rules: [
                                  {
                                    required: true,
                                    type: 'number',
                                    message:
                                      'This is required and must be a number!',
                                  },
                                ],
                              })(
                                <InputNumber
                                  min={0}
                                  onChange={(val) =>
                                    this.setState({
                                      mileageAlert: {
                                        ...this.state.mileageAlert,
                                        threshold: val,
                                      },
                                    })
                                  }
                                />
                              )}

                              <p
                                style={{
                                  marginLeft: 10,
                                  marginTop: 5,
                                  fontWeight: 500,
                                }}
                              >
                                {CurrentUserStore.user.displayedOdometer}
                              </p>
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24} style={{ display: 'flex' }}>
                          <Icon
                            style={{
                              fontSize: 14,
                              marginRight: 8,
                              marginTop: 4,
                            }}
                            type="info-circle"
                          />
                          <i> {this.getMileageAlertText()}</i>
                        </Col>
                      </Row>
                    </>
                  )}
                </RoutineContainer>

                <RoutineContainer>
                  <Form.Item
                    className="routineBased"
                    label="Set Engine Hours Alert?"
                  >
                    <Radio.Group
                      buttonStyle="solid"
                      size="small"
                      name="engineHoursBased"
                      value={this.state.isEngineHoursAlertOn ? 'yes' : 'no'}
                      onChange={() =>
                        this.setState({
                          isEngineHoursAlertOn: !this.state
                            .isEngineHoursAlertOn,
                          isEngineHoursIntervalAlertOn: false,
                          isMileageAlertOn: false,
                          isTimeAlertOn: false
                        })
                      }
                    >
                      <Radio.Button value="no">No</Radio.Button>
                      <Radio.Button value="yes">Yes</Radio.Button>
                    </Radio.Group>
                  </Form.Item>

                  {this.state.isEngineHoursAlertOn && (
                    <>
                      <Row>
                        <Col span={6}>
                          <Form.Item
                            label={
                              <span>
                                Service Start At (X) Engine Hours?
                                <br />
                                <span style={{ fontWeight: 'normal', color: '#888' }}>
                                  (Current Engine Hours as Default)
                                </span>
                              </span>
                            }
                            name="engineHours"
                          >
                            <div style={{ display: 'flex' }}>
                              {getFieldDecorator('started_engine_hours',
                                {
                                  initialValue: this.state.carInfo ? Math.ceil(this.state.carInfo.engineHours/3600) : 1,
                                  rules: [
                                    {
                                      required: true,
                                      type: 'number',
                                      message:
                                        'This is required and must be a number!',
                                    },
                                  ],
                                }
                              )(
                                <InputNumber
                                  min={1}
                                  onChange={(val) => {
                                    let key = 'started';
                                    this.setState({
                                      engineHoursAlert: {
                                        ...this.state.engineHoursAlert,
                                        [`${key}`]: val,
                                      },
                                    });
                                  }}
                                />
                              )}

                              <p
                                style={{
                                  marginLeft: 10,
                                  marginTop: 5,
                                  fontWeight: 500,
                                }}
                              >
                                hours
                              </p>
                            </div>
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Form.Item
                            label={'Service Repeat Every (Y) Engine Hours?'}
                            name="engineHours"
                          >
                            <div style={{ display: 'flex', marginTop: '21px' }}>
                              {getFieldDecorator('interval_engine_hours',
                                {
                                  initialValue: 1,
                                  rules: [
                                    {
                                      required: true,
                                      type: 'number',
                                      message:
                                        'This is required and must be a number!',
                                    },
                                  ],
                                }
                              )(
                                <InputNumber
                                  min={1}
                                  onChange={(val) => {
                                    let key = 'interval';
                                    this.setState({
                                      engineHoursAlert: {
                                        ...this.state.engineHoursAlert,
                                        [`${key}`]: val,
                                      },
                                    });
                                  }}
                                />
                              )}

                              <p
                                style={{
                                  marginLeft: 10,
                                  marginTop: 5,
                                  fontWeight: 500,
                                }}
                              >
                                hours
                              </p>
                            </div>
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Form.Item label="Alert Service Before (Z) Engine Hours?">
                            <div style={{ display: 'flex', marginTop: '21px' }}>
                              {getFieldDecorator('thresholdEngineHours', {
                                initialValue: 1,
                                rules: [
                                  {
                                    required: true,
                                    type: 'number',
                                    message:
                                      'This is required and must be a number!',
                                  },
                                ],
                              })(
                                <InputNumber
                                  min={1}
                                  onChange={(val) =>
                                    this.setState({
                                      engineHoursAlert: {
                                        ...this.state.engineHoursAlert,
                                        threshold: val,
                                      },
                                    })
                                  }
                                />
                              )}

                              <p
                                style={{
                                  marginLeft: 10,
                                  marginTop: 5,
                                  fontWeight: 500,
                                }}
                              >
                                hours
                              </p>
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={24} style={{ display: 'flex' }}>
                          <Icon
                            style={{
                              fontSize: 14,
                              marginRight: 8,
                              marginTop: 4,
                            }}
                            type="info-circle"
                          />
                          <i> {this.getEngineHoursAlertText()}</i>
                        </Col>
                      </Row>
                    </>
                  )}
                </RoutineContainer>
              </>
            </Form>
          </Col>

          <Col span={24}>
            <b>
              <p>Select Vehicle</p>
            </b>
            <SelectCars
              rowSelection={{
                selectedCarIds: selectedCarIds,
                onChange: this.onSelect,
                type: 'radio'
              }}
            />

            <Button
              style={{ marginTop: '10px' }}
              type="primary"
              onClick={this.onSubmit}
            >
              Add Service
            </Button>
          </Col>

          {/* {ShopStore.currentShop.id !== -1 && (
            <Col span={24}>
              <RoutinesTableList />
            </Col>
          )} */}
        </Row>
      </>
    );
  }
}

RoutineServiceAntdForm.propTypes = {
  form: PropTypes.object,
};

const RoutineServiceForm = Form.create({ name: 'routine-service' })(
  observer(RoutineServiceAntdForm)
);

export default RoutineServiceForm;
