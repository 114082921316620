import React from 'react';

interface IWorkOrderLineItemContext {
  header: string,
  key: string,
  title: string,
  description: string,
  laborTotal: number,
  partsTotal: number,
  labours: any[],
  parts: any[],
  services: any[],
  expanded: boolean,
}

const WorkOrderLineItemContext = React.createContext(
  {} as IWorkOrderLineItemContext
);

export const WorkOrderLineItemProvider = WorkOrderLineItemContext.Provider;
export const WorkOrderLineItemConsumer = WorkOrderLineItemContext.Consumer;

export default WorkOrderLineItemContext;
