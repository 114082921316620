import React, { useEffect, useRef, useState } from 'react';
import { Button, Typography, Checkbox } from 'antd';
import styled from 'styled-components';
import { ReactComponent as TableIcon } from 'assets/icons/table.svg';

const { Text } = Typography;
const CheckboxGroup = Checkbox.Group;

const DropDownComponent = styled.div`
  position: relative;
  button {
    align-items: center;
    display: flex;
    gap: 8px;

    &.active {
      color: #123fbf;
    }

    span {
      line-height: 1;
    }
  }

  .filter-dropdown-list {
    padding: 8px 12px;
    border-bottom: solid 1px #e8e8e8;

    .filter-dropdown-list-item {
      margin-bottom: 5px;
    }
  }

  .filter-dropdown-actions {
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
  }

  .ant-typography {
    font-size: 14px;
    font-weight: 400;
    padding: 0 11px;
  }
  .options {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background: #fff;
    min-width: 96px;
    padding-top: 5px;
    left: -27px;
    position: absolute;
    z-index: 30;

    .ant-checkbox-group-item.ant-checkbox-wrapper {
      align-items: flex-end;
      display: flex;
      margin-bottom: 5px;

      span {
        line-height: 1;
      }
    }
  }
`;

interface Options {
  tableName: string;
  fixedColumns: Array<string>;
  columns: Array<any>;
  defaultColumns: Array<string>;
  onSubmit: (v: any) => void;
  onReset: (v: any) => void;
}

const CustomTableColumn = ({
  tableName,
  fixedColumns,
  columns,
  defaultColumns,
  onSubmit,
  onReset,
}: Options) => {
  const ref = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<
    Array<{ label: string; value: string; disabled?: boolean }>
  >([]);
  const [selectedOptions, setSelectedOptions] = useState<Array<string>>([]);

  useEffect(() => {
    setLoading(true);
    const selectedItems =
      Array.isArray(defaultColumns) && defaultColumns.length > 0
        ? defaultColumns
        : columns.map((col) => col.key);

    const initialSelected = [
      ...new Set([...(fixedColumns || []), ...selectedItems]),
    ];

    setSelectedOptions(initialSelected);

    const columnsToDict = columns
      .filter((f) => f.title !== '' && f.title !== null)
      .map((item) => ({
        value: item.key,
        label: item.title,
        disabled: fixedColumns.includes(item.key),
      }));

    setData(columnsToDict);
    setLoading(false);
  }, [columns, defaultColumns, fixedColumns]);

  useEffect(() => {
    document.addEventListener('mousedown', outsideClick);

    return () => {
      document.removeEventListener('mousedown', outsideClick);
    };
  }, []);

  const outsideClick = (evt: MouseEvent) => {
    if (ref.current && !ref.current.contains(evt?.target as Node)) {
      setVisible(false);
    }
  };

  const toggleVisibleChange = () => {
    setVisible((prevVisible) => !prevVisible);
  };

  const onSelectItem = (values: any) => {
    const mergedValues = [...new Set([...(fixedColumns || []), ...values])];
    setSelectedOptions(mergedValues);
  };

  const handlerSubmit = async () => {
    onSubmit({
      name: tableName,
      columns: selectedOptions,
    });

    setVisible(false);
  };

  const handlerReset = async () => {
    setSelectedOptions(fixedColumns);
    onReset({ name: tableName });
    setVisible(false);
  };

  return (
    <>
      {!loading && (
        <DropDownComponent>
          <Button
            onClick={() => toggleVisibleChange()}
            className={visible ? 'active' : ''}
          >
            <TableIcon fill={visible ? '#123FBF' : '#595959'} />
            Customize table
          </Button>
          {visible && (
            <div
              className="options"
              ref={ref}
              onClick={(e) => e.stopPropagation()}
            >
              <Text>Select columns to show</Text>
              <div className="filter-dropdown-list">
                <CheckboxGroup
                  options={data}
                  defaultValue={selectedOptions}
                  value={selectedOptions}
                  onChange={onSelectItem}
                />
              </div>

              <div className="filter-dropdown-actions">
                <Button onClick={handlerReset} size="small">
                  Reset
                </Button>
                <Button type="primary" size="small" onClick={handlerSubmit}>
                  OK
                </Button>
              </div>
            </div>
          )}
        </DropDownComponent>
      )}
    </>
  );
};

export default CustomTableColumn;
