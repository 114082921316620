import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { decorate } from 'mobx';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Col, Row, Button } from 'antd';
import WorkOrderLineItemLaborItem from './WOLineItemLaborsItem';
import WorkOrderLineItemContext from '../LineItems/WorkOrderLineItemContext';

class WorkOrderLineItemLabors extends Component {
  static contextType = WorkOrderLineItemContext;

  static propTypes = {
    childRef: PropTypes.func,
    onUpdateLineTotal: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.any,
    onAddItem: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func,
    shopUsers: PropTypes.array,
    onUpdateLocalItem: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
  };

  state = {};

  laborsItemsRefs = {};

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  onRemoveItem = (event, itemKey) => {
    if (this.props.onRemoveItem) {
      this.props.onRemoveItem(event, itemKey);
    }
  };

  onUpdateLineTotal = (index, lineTotal) => {
    if (this.props.onUpdateLineTotal) {
      this.props.onUpdateLineTotal(index, lineTotal);
    }
  };

  render() {
    const { screenSize } = this.context;

    if (!_.isEmpty(this.props.items)) {
      const addDescription = `Add ${this.props.description}`;
      return (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            {this.props.items.map((mapItem, index) => {
              return (
                <div key={mapItem.key}>
                  <WorkOrderLineItemLaborItem
                    displayIcon={index === 0}
                    key={mapItem.key}
                    labor={mapItem}
                    childRef={(ref) => (this.laborsItemsRefs[index] = ref)}
                    shopUsers={this.props.shopUsers}
                    onUpdateTotal={(lineTotal) =>
                      this.onUpdateLineTotal(index, lineTotal)
                    }
                    onRemoveItem={this.onRemoveItem}
                    onUpdateLocalItem={(newVal, whatChanged) =>
                      this.props.onUpdateLocalItem(
                        mapItem.key,
                        newVal,
                        whatChanged
                      )
                    }
                    isDisabled={this.props.isDisabled}
                  />
                  {index === this.props.items.length - 1 && (
                    <Button
                      style={{
                        display: 'block',
                        marginLeft: screenSize <= 575 ? 0 : 35,
                      }}
                      icon="plus-circle"
                      type="link"
                      onClick={this.props.onAddItem}
                    >
                      {addDescription}
                    </Button>
                  )}
                </div>
              );
            })}
          </Col>
        </Row>
      );
    }
    return <></>;
  }
}

decorate(WorkOrderLineItemLabors, {});

export default observer(WorkOrderLineItemLabors);
