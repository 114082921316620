import React from 'react';
import { Alert, Icon } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledAlert = styled(Alert)`
  && {
    align-items: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    display: flex;
    gap: 15px;

    .ant-alert-icon {
      color: #123fbf;
      font-size: 40px;
    }

    .ant-alert-message {
      color: black;
      font-weight: bold;
    }

    .ant-alert-description {
      color: black;
    }

    .ant-alert-close-icon {
      align-items: center;
      border: 2px solid #000;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      padding: 2px;
      color: #000;
    }

    .ant-alert-close-icon i {
      color: #000;
    }
  }
`;

const AppAlert = ({ message, icon, onClose }) => {
  return (
    <StyledAlert
      description={message}
      type="info"
      icon={<Icon type="info-circle" fill={'#123FBF'} />}
      showIcon
      closable
      onClose={onClose}
    />
  );
};

AppAlert.propTypes = {
  message: PropTypes.any.isRequired,
  onClose: PropTypes.func,
};

AppAlert.defaultProps = {
  onClose: () => {},
};

export default AppAlert;
